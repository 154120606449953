
export class PerguntaCaracteristica {
    cliente: string;
    codigo: string;
    descricao: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.descricao = "";
    }
}