import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormIdentificacaoComponent } from "src/app/pages/outros/form-identificacao/form-identificacao.component";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormResponsavelComponent } from "src/app/pages/outros/form-responsavel/form-responsavel.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Empresa } from "src/app/model/empresa";
import { Atividade } from "src/app/model/atividade";
import { ComprovanteAlteracao } from "src/app/components/pdf/comprovanteAlteracao";
import { Resposta } from '../../../../model/resposta';
import { MatCheckbox } from "@angular/material/checkbox";
import { FormTipoNegocioComponent } from "../../form-tipo-negocio/form-tipo-negocio.component";
import { ConfiguracaoService } from "src/app/services/configuracao/configuracao.service";
import { AtendimentoRequisitoComponent } from "../../atendimento/atendimento-protocolo/atendimento-requisito.component";
import { RequisitoService } from "src/app/services/requisito/requisito.service";
import { Atendimento } from "src/app/model/atendimento";

@Component({
    selector: "app-alteracao-oficio",
    templateUrl: "./alteracao-oficio.component.html"
})
export class AlteracaoOficioComponent implements OnInit {
    
    tipoEmpresa: string;
    tipoEmpresaAuxiliar: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    tipoPergunta = Constantes.TIPO_DE_PERGUNTA;
    servicos: Array<Servico>;
    empresa: Empresa;
    protocolo: Protocolo;
    protocoloTemporario: Protocolo;
    documentos: Array<Documento>;
    atividadesAlteradas: boolean = false;
    exercidas: Array<Atividade>;
    naoExercidas: Array<Atividade>;

    liberarPreenchimentoQuestionarioPorLocalizacao: boolean = true;
    ativarOpcaoDeAnaliseViabilidade: boolean = true;

    // Formulários
    formAlteracao: FormGroup;
    formularioRequerente: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioTipoNegocio: FormGroup;
    formularioIdentificacaoNegocio: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioResponsavel: FormGroup;
    formularioQuestionarioViabilidade: FormGroup;
    formularioQuestionarioFic: FormGroup;
    formularioResumoAlteracao: FormGroup;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(FormTipoNegocioComponent)
    formTipoNegocio: FormTipoNegocioComponent;

    @ViewChild(FormIdentificacaoComponent)
    formIdentificacao: FormIdentificacaoComponent;
    
    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild(AtendimentoRequisitoComponent)
    atendimentoRequisito: AtendimentoRequisitoComponent;

    @ViewChild("formQuestionarioViabilidade")
    formQuestionarioViabilidade: FormQuestionarioComponent;

    @ViewChild("formQuestionarioFic")
    formQuestionarioFic: FormQuestionarioComponent;

    @ViewChild(FormResponsavelComponent)
    formResponsavel: FormResponsavelComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    emitirErroIdentificacao: boolean;
    mensagemErro: string;

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private empresaService: EmpresaService,
        private configuracaoService: ConfiguracaoService,
        private requisitoService: RequisitoService) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];    

        this.empresa = new Empresa();
        this.empresa.registro.documentos = [new Documento()];

        this.protocolo = new Protocolo();
        this.protocoloTemporario = new Protocolo();
        this.protocoloTemporario.cliente = localStorage.getItem("pId");
        this.protocoloTemporario.viabilidade.perguntasUsuario = new PerguntaResposta();
        this.protocoloTemporario.fic.perguntasUsuario = new PerguntaResposta();

        this.formAlteracao = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.informativo = Constantes.INFORMATIVO_ALTERACAO_EMPRESA_MEI[0];

        this.documentos = new Array<Documento>();

        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }, 0);

        this.processo = new Processo();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => { 
                if (processo.codigo === "A" && processo.tipo === "S" && processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = 'U';
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = 'U';
                    this.protocoloTemporario.processo = this.processo;
                    this.servicos = processo.servicos;

                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                        this.processo.tipo = 'I';
                        processo.servicos.forEach(servico => {
                            if (servico.codigo == 1) {
                                this.servicos = [servico];
                            }
                        });
                    }    
                } 
            }
        );

        this.emitirErroIdentificacao = false;
        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    atualizarTipoEmpresa(acaoAtendimento) {
        this.tipoEmpresaAuxiliar = acaoAtendimento.tipoEmpresaFormulario;
       
        this.formIdentificacaoNegocio.tipoEmpresa = this.tipoEmpresaAuxiliar;
        this.formLocalizacao.tipoEmpresa = this.tipoEmpresaAuxiliar;
        this.formIdentificacao.tipoEmpresa = this.tipoEmpresaAuxiliar;
        this.formAtividade.tipoEmpresa = this.tipoEmpresaAuxiliar;
        this.formResponsavel.tipoEmpresa = this.tipoEmpresaAuxiliar;
        this.formResumo.tipoEmpresa = this.tipoEmpresaAuxiliar;
        
   }

    buscarEmpresa() {
        let cnpj = "";
        
        this.empresa.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)
                cnpj = documento.numero;
        });

        if (cnpj.replace(/\D/g,"") == this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,"")
            && this.empresa.inscricao.replace(/\D/g,"") == this.formIdentificacao.im.value.replace(/\D/g,"")) {
                this.formIdentificacao.controleValidade.setValue(true);
                this.stepper.next();
                return false;
        }

        this.empresaService
            .buscarParaAlteracao(
                this.protocoloTemporario.cliente, 
                this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,""), 
                this.formIdentificacao.im.value.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                this.empresa = retorno;

                if(this.empresa.registro.tipoPessoa == 'F' && (this.formRequerente.formTipoNegocio.tipoNegocio.value == 'AE' || this.formRequerente.formTipoNegocio.tipoNegocio.value == 'AM')){
                    Swal.fire("", "Tipo de negócio não confere com os dados de identificação do negócio, verifique a informação e tente novamente.", "error");
                    return false;
                }
                if((this.empresa.registro.tipoPessoa == 'J' || this.empresa.registro.tipoPessoa == 'M') && this.formRequerente.formTipoNegocio.tipoNegocio.value == 'AA'){
                    Swal.fire("", "Tipo de negócio não confere com os dados de identificação do negócio, verifique a informação e tente novamente.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Nao_Existe) {
                    Swal.fire("", "Inscrição Municipal não localizada na base de dados Municipal, verifique a informação tente novamente.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Suspensa) {
                    Swal.fire({
                        title: "",
                        html: `Caro contribuinte, esse negócio encontra-se suspenso por ofício, entre em contato com a Prefeitura e verifique como proceder.`,
                        icon: "warning"
                    })
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento) {
                    console.log(this.empresa);
                    Swal.fire("", "Alteração de ofício não permitida - negócio com processo em andamento - protocolo " + this.empresa.protocolo, "info");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Empresa_Encerrada) {
                    Swal.fire({
                        title: "",
                        html: `Inscrição Municipal foi baixada em ${ this.empresa.encerramento.data }, não é possível solicitar uma alteração.`,
                        icon: "warning"
                    })
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Ativa) {
                    this.formIdentificacao.controleValidade.setValue(true);
                    Swal.fire("", "Inscrição encontrada, pode seguir com os passos de alteração!", "success");
                    this.preencherCamposIdentificacaoNegocio();
                    this.preencherCamposEndereco();
                    this.preencherCamposAtividadeAutonomo();
                    this.preencherCamposResponsavel();
                    //this.preencherCamposQuestionarioViabilidade();
                    //this.preencherCamposQuestionarioFic();
                    this.preencherCamposQuestionario();
                    this.stepper.next();
                }
            })
    }

    carregarIdentificacao() {
        this.formIdentificacaoNegocio.capitalSocial.enable();
        this.configuracao.processos.forEach( processo => {
            if (processo.codigo == "A" && processo.tipo == "S") {
                processo.servicos.forEach( servico => {
                    if (servico.codigo == this.formRequerente.orgaoDeRegistro.value) {
                        servico.naturezaJuridicas.forEach( natureza => {
                            if (natureza.codigo == this.formRequerente.naturezaJuridica.value) {
                                this.documentos = natureza.documentos;
                                this.formResponsavel.socioRegras = natureza.socioRegras;
                                this.formResponsavel.obrigatorioContador = natureza.obrigatorioContador;
                                if (!natureza.possuiCapitalSocial) {
                                    this.formIdentificacaoNegocio.capitalSocial.disable();
                                }
                            }
                        })
                    }
                })
            }
        });
    }

    preencherCamposIdentificacaoNegocio() {
        this.formIdentificacaoNegocio.preencherCampos(this.empresa);

        if(this.protocolo.processo.codigo == 'U' && Constantes.TIPO_EMPRESA.Autonomo){
            this.formIdentificacaoNegocio.cpfAutonomo.disable();
        }

    }

    preencherCamposEndereco() {
        this.formLocalizacao.exibeFormularioEndereco = !this.empresa.viabilidade.enderecos.length;
        this.empresa.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.formLocalizacao.preenchimentoInicial= false;
                this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
            }
        })
    }


    preencherCamposAtividadeAutonomo() {

        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        this.formAtividade.atividadesSelecionadas = this.empresa.viabilidade.atividadesExercidas;

        this.formAtividade.tipoUnidade.setValue(this.empresa.viabilidade.tipoUnidade);
        this.formAtividade.tipoUnidadeAuxiliar.setValue(this.empresa.viabilidade.tipoUnidadeAuxiliar);
        
        this.empresa.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.formAtividade.formasAtuacaoSelecionadas.push(formaAtuacao.codigo);
        })
        this.formAtividade.descricaoAtividadeEconomica.setValue(this.empresa.viabilidade.descricaoAtividadeEconomica);
        this.formAtividade.montarQuantidadeAtividade();
    }

    preencherCamposAtividade() {        
        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        var atividadesDaEmpresa = this.empresa.viabilidade.atividadesExercidas.concat(this.empresa.viabilidade.atividadesNaoExercidas);

        this.formAtividade.atividades.forEach( atividade => {
            var existeAtividade = atividadesDaEmpresa.findIndex( item => item.codigo == atividade.codigo);
            if (existeAtividade > -1) {
                this.formAtividade.atividadesSelecionadas.push(atividade);
                this.formAtividade.atividadesSelecionadas[this.formAtividade.atividadesSelecionadas.length - 1].exercida = atividadesDaEmpresa[existeAtividade].exercida;
                this.formAtividade.atividadesSelecionadas[this.formAtividade.atividadesSelecionadas.length - 1].principal = atividadesDaEmpresa[existeAtividade].principal;
            }
        });
        
        this.formAtividade.tipoUnidade.setValue(this.empresa.viabilidade.tipoUnidade);
        this.formAtividade.tipoUnidadeAuxiliar.setValue(this.empresa.viabilidade.tipoUnidadeAuxiliar);
        
        this.empresa.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.formAtividade.formasAtuacaoSelecionadas.push(formaAtuacao.codigo);
        })
        this.formAtividade.descricaoAtividadeEconomica.setValue(this.empresa.viabilidade.descricaoAtividadeEconomica);

        this.formAtividade.montarQuantidadeAtividade();
    }

    preencherCamposResponsavel() {
       this.formResponsavel.socios = this.empresa.fic.socios;
    }

    preencherCamposQuestionario() {
        this.empresa.viabilidade.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionarioViabilidade.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
        this.empresa.fic.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionarioFic.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
    }

    preencherCamposQuestionarioViabilidade() {
        this.formQuestionarioViabilidade.perguntasRespondidasOutrosProcessos = new Array<Pergunta>();

        this.empresa.viabilidade.perguntasUsuario.perguntas.forEach( pergunta => {
            var perguntaAuxiliar                = new Pergunta();
            perguntaAuxiliar.cliente            = pergunta.cliente;
            perguntaAuxiliar.codigo             = pergunta.codigo;
            perguntaAuxiliar.codigoAutomacao    = pergunta.codigoAutomacao;
            perguntaAuxiliar.criticada          = pergunta.criticada;
            perguntaAuxiliar.nome               = pergunta.nome;
            perguntaAuxiliar.tipo               = pergunta.tipo;
            perguntaAuxiliar.fixa               = pergunta.fixa;
            perguntaAuxiliar.grupo              = pergunta.grupo;
            perguntaAuxiliar.grupoDescricao     = pergunta.grupoDescricao;
            perguntaAuxiliar.obrigatorio        = pergunta.obrigatorio;
            perguntaAuxiliar.processo           = pergunta.processo;

            if (pergunta.respostas.length) {
                var respostaAuxiliar            = new Resposta();
                respostaAuxiliar.cliente        = pergunta.respostas[0].cliente;
                respostaAuxiliar.codigo         = pergunta.respostas[0].codigo;
                respostaAuxiliar.nome           = pergunta.respostas[0].nome;
                respostaAuxiliar.ativa          = pergunta.respostas[0].ativa;
                respostaAuxiliar.fatorDeRisco   = pergunta.respostas[0].fatorDeRisco;
    
                perguntaAuxiliar.respostas.push(respostaAuxiliar);
            }

            this.formQuestionarioViabilidade.perguntasRespondidasOutrosProcessos.push(perguntaAuxiliar);
        })
    }

    preencherCamposQuestionarioFic() {
        this.formQuestionarioFic.perguntasRespondidasOutrosProcessos = new Array<Pergunta>();

        this.empresa.fic.perguntasUsuario.perguntas.forEach( pergunta => {
            var perguntaAuxiliar                = new Pergunta();
            perguntaAuxiliar.cliente            = pergunta.cliente;
            perguntaAuxiliar.codigo             = pergunta.codigo;
            perguntaAuxiliar.codigoAutomacao    = pergunta.codigoAutomacao;
            perguntaAuxiliar.criticada          = pergunta.criticada;
            perguntaAuxiliar.nome               = pergunta.nome;
            perguntaAuxiliar.tipo               = pergunta.tipo;
            perguntaAuxiliar.fixa               = pergunta.fixa;
            perguntaAuxiliar.grupo              = pergunta.grupo;
            perguntaAuxiliar.grupoDescricao     = pergunta.grupoDescricao;
            perguntaAuxiliar.obrigatorio        = pergunta.obrigatorio;
            perguntaAuxiliar.processo           = pergunta.processo;

            if (pergunta.respostas.length) {
                var respostaAuxiliar            = new Resposta();
                respostaAuxiliar.cliente        = pergunta.respostas[0].cliente;
                respostaAuxiliar.codigo         = pergunta.respostas[0].codigo;
                respostaAuxiliar.nome           = pergunta.respostas[0].nome;
                respostaAuxiliar.ativa          = pergunta.respostas[0].ativa;
                respostaAuxiliar.fatorDeRisco   = pergunta.respostas[0].fatorDeRisco;
    
                perguntaAuxiliar.respostas.push(respostaAuxiliar);
            }

            this.formQuestionarioFic.perguntasRespondidasOutrosProcessos.push(perguntaAuxiliar);
        })
    }

    avancarPassoIdentificacao(passos) {

        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if(this.formRequerente.formTipoNegocio.tipoNegocio.value == ''){
            Swal.fire("", "Selecione o tipo do negócio.", "error");
            return false;    
        }

        this.formularioRequerente = this.formRequerente.formulario;
        this.formularioIdentificacao = this.formIdentificacao.formulario;
        //this.formAtividade.tipoEmpresa = this.formRequerente.formTipoNegocio.tipoNegocio.value;
        this.verificaValidacaoFormulario(this.formularioRequerente);
        this.verificaValidacaoFormulario(this.formularioIdentificacao);
        
        if (this.formularioRequerente.valid && this.formIdentificacao.cpfCnpjEmpresa.valid && this.formIdentificacao.im.valid) {
            this.emitirErroIdentificacao = false;
            this.carregarIdentificacao();
            this.buscarEmpresa();
        }
        else {
            this.formIdentificacao.controleValidade.setValue("");
            let mensagemErro = "";
            
            if (this.formularioRequerente.invalid) {
                mensagemErro += "Favor corrigir os campos nos dados do requerente e/ou local de registro da empresa.<br>"
            }
            
            if (this.formularioIdentificacao.invalid) {
                mensagemErro += "Favor corrigir os campos de identificação da empresa.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErroIdentificacao = true;
            this.mensagemErro = mensagemErro;
        }
        
    }

    avancarPassoIdentificacaoNegocio() {
        this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
        this.verificaValidacaoFormulario(this.formIdentificacaoNegocio.formulario);
        if (this.formularioIdentificacaoNegocio.valid) {
            this.formIdentificacaoNegocio.emitirErro = false;
            this.formIdentificacaoNegocio.mensagemErro = "";
            this.stepper.next();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioIdentificacaoNegocio.invalid) {
                mensagemErro += "Favor corrigir os campos obrigatórios.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.formIdentificacaoNegocio.emitirErro = true;
            this.formIdentificacaoNegocio.mensagemErro = mensagemErro;
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formQuestionarioViabilidade.liberarPreenchimento = true;

        this.empresa.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                if(endereco.cep != this.formLocalizacao.enderecoEmpresarial.cep ||
                   endereco.inscricao != this.formLocalizacao.enderecoEmpresarial.inscricao ||
                   endereco.tipoLogradouro != this.formLocalizacao.enderecoEmpresarial.tipoLogradouro ||
                   endereco.logradouro != this.formLocalizacao.enderecoEmpresarial.logradouro ||
                   endereco.numero != this.formLocalizacao.enderecoEmpresarial.numero ||
                   endereco.complemento != this.formLocalizacao.enderecoEmpresarial.complemento ||
                   endereco.estado != this.formLocalizacao.enderecoEmpresarial.estado ||
                   endereco.cidade != this.formLocalizacao.enderecoEmpresarial.cidade ||
                   endereco.bairro != this.formLocalizacao.enderecoEmpresarial.bairro) {
                    this.formQuestionarioViabilidade.liberarPreenchimento = true;
                    this.liberarPreenchimentoQuestionarioPorLocalizacao = true;
                }
                else {
                    this.liberarPreenchimentoQuestionarioPorLocalizacao = true;
                }
            }
        });
        
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if(this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Autonomo){
            if (this.formLocalizacao.validarFormulario()) {
                var mensagemHtml = "";
    
                    if (this.formLocalizacao.apenasCorrespondencia.value) {
                        mensagemHtml = 'Caro contribuinte, ao avançar com a alteração será classificado como <b>Autônomo Não Estabelecido</b>. <br><br>Caso seja um Autônomo Estabelecido, selecione Cancelar, em seguida selecione a opção Editar no Endereço do Meu Negócio e desmarque a opção: <br><br><b>Não possuo estabelecimento fixo, autônomo não estabelecido.</b>'}
                    else {
                        mensagemHtml = 'Caro contribuinte, ao avançar com a alteração será classificado como <b>Autônomo Estabelecido</b>. <br><br>Caso seja um Autônomo Não Estabelecido, selecione Cancelar, em seguida selecione a opção Editar no Endereço do Meu Negócio e selecione a opção: <br><br><b>Não possuo estabelecimento fixo, autônomo não estabelecido.</b>'
                    }
    
                Swal.fire({
                title: "", 
                html: mensagemHtml,
                icon: 'question',
                showCancelButton: true})
                .then((result) => {
                    if (result.value) {
                        this.stepper.next();
                    }
                });
            }
        }    
        else{
            if (this.formLocalizacao.validarFormulario())
                this.stepper.next();    
        }
        
        
    }

    avancarPassoAtividade() {
    
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    renomearBotaoResponsavel() {
        this.btnAnteriorResponsavel = "Anterior";
        this.btnAvancarResponsavel = "Avançar";

        if (this.formResponsavel.editarResponsavel) {
            this.btnAnteriorResponsavel = "Cancelar";
            this.btnAvancarResponsavel = "Confirmar";
        }
    }

    verificaMudancaCnae(){
        this.formQuestionarioViabilidade.liberarPreenchimento = this.liberarPreenchimentoQuestionarioPorLocalizacao;

        if(this.empresa.viabilidade.atividadesExercidas.length != this.formAtividade.quantidadeExercida ||
            this.empresa.viabilidade.atividadesNaoExercidas.length != this.formAtividade.quantidadeNaoxercida){
            this.formQuestionarioViabilidade.liberarPreenchimento = true;
        }
 
        if(!this.formQuestionarioViabilidade.liberarPreenchimento){
            this.exercidas = this.formAtividade.atividadesSelecionadas.filter(atividade => atividade.exercida == true);
            this.naoExercidas = this.formAtividade.atividadesSelecionadas.filter(atividade => atividade.exercida == false);
 
            this.empresa.viabilidade.atividadesExercidas
            .forEach(cnae => {
                if (!this.exercidas.filter(atividade => atividade.codigo == cnae.codigo).length){
                    this.formQuestionarioViabilidade.liberarPreenchimento = true;
                }
                else if (cnae.principal && !this.exercidas.filter(atividade => atividade.codigo == cnae.codigo && atividade.principal == cnae.principal).length) {
                    this.formQuestionarioViabilidade.liberarPreenchimento = true;
                }
            }); 
 
            this.empresa.viabilidade.atividadesNaoExercidas
            .forEach(cnae => {
                if (!this.naoExercidas.filter(atividade => atividade.codigo == cnae.codigo).length){
                    this.formQuestionarioViabilidade.liberarPreenchimento = true;
                }
                else if (cnae.principal && !this.naoExercidas.filter(atividade => atividade.codigo == cnae.codigo && atividade.principal == cnae.principal).length) {
                    this.formQuestionarioViabilidade.liberarPreenchimento = true;
                }
            });
        }
        
        this.ativarOpcaoDeAnaliseViabilidade = !this.formQuestionarioViabilidade.liberarPreenchimento;
    }

    voltarPassoResponsavel() {
        if (this.formResponsavel.editarResponsavel) {
            this.formResponsavel.toggle();
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoResponsavel() {
        this.formularioResponsavel = this.formResponsavel.formulario;
        if (this.formResponsavel.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioViabilidade.formulario);
        this.verificaValidacaoFormulario(this.formQuestionarioFic.formulario);
        if (this.formQuestionarioViabilidade.validarFormulario() && this.formQuestionarioFic.validarFormulario()) {
            this.stepper.next();
        }
    }

    acaoAnalisarViabilidade(chkAnalisarViabilidade: MatCheckbox) {
        this.protocoloTemporario.viabilidade.analisar = chkAnalisarViabilidade.checked;
        this.formQuestionarioViabilidade.liberarPreenchimento = chkAnalisarViabilidade.checked;
        if (this.formQuestionarioViabilidade.liberarPreenchimento) {
            this.formQuestionarioViabilidade.liberarPerguntas();
        }
        else {
            this.montagemQuestionarioViabilidade();
        }
    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }
        // Problema de quando entrava na pagina de atividade já dava erro direto
        // if ( this.passoAtual == 2 ) {
        //     this.formAtividade.validarFormulario();
        // }

        if (event.previouslySelectedIndex == 1) {
            this.formResponsavel.capitalSocialMatriz = this.formIdentificacaoNegocio.capitalSocial.value;
        }
        
        if (event.selectedIndex == 0) {
            this.formIdentificacao.controleValidade.setValue("");
        }

        if (event.selectedIndex == 1) {
            this.formIdentificacaoNegocio.cnpj.disable();
            this.formIdentificacaoNegocio.dataCnpj.disable();

            if (this.formIdentificacaoNegocio.dataCnpj.value.trim() == "")
                this.formIdentificacaoNegocio.dataCnpj.enable();
        }
        
        if (event.selectedIndex == 2) {
            this.formLocalizacao.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 3) {
            this.formAtividade.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 5) {
            this.formResponsavel.controleValidade.setValue("");
        }
        
        if (event.selectedIndex == 6) {

            if(this.formRequerente.formTipoNegocio.tipoNegocio.value == 'AA'){
                // Questionário de Viabilidade
                this.formQuestionarioViabilidade.controleValidade.setValue("");
                this.formQuestionarioViabilidade.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        
                let perguntaViabilidade = new Pergunta();
                perguntaViabilidade.cliente = localStorage.getItem("pId");
                perguntaViabilidade.tipo = this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo;
                perguntaViabilidade.atividades = this.formAtividade.atividadesSelecionadas;
                
                this.formQuestionarioViabilidade.carregarPerguntas(perguntaViabilidade);
                this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;

                // Questionário de Fic
                this.formQuestionarioFic.controleValidade.setValue("");
                this.formQuestionarioFic.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        
                let perguntaFic = new Pergunta();
                perguntaFic.cliente = localStorage.getItem("pId");
                perguntaFic.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : 
                                    this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo;
                perguntaFic.atividades = this.formAtividade.atividadesSelecionadas;
                
                this.formQuestionarioFic.carregarPerguntas(perguntaFic);
                this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            }
            else{
                // Questionário de Viabilidade
                this.montagemQuestionarioViabilidade();

                // Questionário de Fic
                this.formQuestionarioFic.controleValidade.setValue("");
                this.formQuestionarioFic.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        
                let perguntaFic = new Pergunta();
                perguntaFic.cliente = localStorage.getItem("pId");
                perguntaFic.tipo = Constantes.TIPO_DE_PERGUNTA.Fic;
                perguntaFic.atividades = this.formAtividade.atividadesSelecionadas;
                
                this.formQuestionarioFic.carregarPerguntas(perguntaFic);
                this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            }
            
        }
        
        this.informativo = Constantes.INFORMATIVO_ALTERACAO_EMPRESA_MEI[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarAlteracao(event);
    }

    montagemQuestionarioViabilidade() {
        this.formQuestionarioViabilidade.controleValidade.setValue("");
        this.formQuestionarioViabilidade.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;

        let perguntaViabilidade = new Pergunta();
        perguntaViabilidade.cliente = localStorage.getItem("pId");
        perguntaViabilidade.tipo = Constantes.TIPO_DE_PERGUNTA.Viabilidade;
        perguntaViabilidade.atividades = this.formAtividade.atividadesSelecionadas;
        
        this.verificaMudancaCnae();
        
        //if (!this.formQuestionarioViabilidade.liberarPreenchimento) {
            this.formQuestionarioViabilidade.perguntasRespondidas = new Array<Pergunta>();
            this.preencherCamposQuestionarioViabilidade();
        //}

        this.formQuestionarioViabilidade.carregarPerguntas(perguntaViabilidade);
        this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
    }

    montarAlteracao(event) {

        if ( event.previouslySelectedIndex == 0) {

            this.montarConfiguracao();
            
            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocoloTemporario.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.protocoloTemporario.requerente = this.formRequerente.requerente;
            this.protocoloTemporario.registro = this.formRequerente.registro;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];

         
            this.protocolo.data = new Date().toLocaleDateString();
            this.formIdentificacaoNegocio.protocolo = this.protocolo;
            this.formIdentificacaoNegocio.cpfAutonomo = this.formIdentificacao.cpfCnpjEmpresa.value;

            // Ajuste de fluxo - os fluxos são fixos no antigo Icad - ver forma de deixar dinâmico
            // Código de serviço tem que ser 20 para Empresa e 24 para Mei
            if (this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Empresa) {
                this.processo.servicos[0].codigo = 84;
            }
            else if (this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Mei) {
                this.processo.servicos[0].codigo = 85;
            }
            else if (this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Autonomo) {
                this.processo.servicos[0].codigo = 87;
                //SETANDO FLUXO DE AUTONOMO NÃO ESTABELECIDO NA MÃOs
                if(this.formLocalizacao.apenasCorrespondencia.value){
                    this.processo.servicos[0].codigo = 88;
                    this.protocoloTemporario.registro.estabelecido = "N";
                }
            }

            this.protocoloTemporario.data = new Date().toLocaleDateString("pt-br");
            this.formIdentificacaoNegocio.protocolo = this.protocoloTemporario;
            
        }
        else if ( event.previouslySelectedIndex == 1) {
            this.formIdentificacaoNegocio.preencher();
            this.protocoloTemporario = this.formIdentificacaoNegocio.protocolo;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.protocoloTemporario.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;

            if (this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Autonomo) {
                //SETANDO FLUXO DE AUTONOMO ESTABELECIDO NA MÃO
                this.processo.servicos[0].codigo = 87;
                this.protocoloTemporario.registro.estabelecido = "S";

                //SETANDO FLUXO DE AUTONOMO NÃO ESTABELECIDO NA MÃOs
                if(this.formLocalizacao.apenasCorrespondencia.value){
                    this.processo.servicos[0].codigo = 88;
                    this.protocoloTemporario.registro.estabelecido = "N";
                }
            }
            
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formAtividade.preencher();

            this.protocoloTemporario.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            this.protocoloTemporario.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

            this.protocoloTemporario.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocoloTemporario.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocoloTemporario.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocoloTemporario.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;

            this.inicializarRequisitos(); 
        }
        else if (event.previouslySelectedIndex == 4) {
            var atendimento = new Atendimento();
            atendimento.requisitos = this.atendimentoRequisito.requisitosAdicionados;
            this.protocoloTemporario.viabilidade.atendimentos.push(atendimento);
            this.protocoloTemporario.viabilidade.requisitos = this.atendimentoRequisito.requisitosRemovidos;
        }
        else if (event.previouslySelectedIndex == 5) {
            this.protocoloTemporario.fic.socios = this.formResponsavel.socios;
        }
        else if (event.previouslySelectedIndex == 6) {
            this.formQuestionarioViabilidade.preencher();
            this.protocoloTemporario.viabilidade.perguntasUsuario = this.formQuestionarioViabilidade.perguntasRespostas;
            
            this.formQuestionarioFic.preencher();
            this.protocoloTemporario.fic.perguntasUsuario = this.formQuestionarioFic.perguntasRespostas;
        }
        
        this.protocoloTemporario.inscricao = this.empresa.inscricao;
        this.formResumo.empresa = this.empresa;
        this.formResumo.protocolo = this.protocoloTemporario;
        this.formResumo.preencher();
    }

    gerarAlteracao() {

        this.formResumo.preencherAnexos();

        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);

        this.protocoloService
            .gerarAlteracaoPorOficio(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        this.router.navigate(["/operacao/oficio"]);
                    }
                })
            });
    }

    montarConfiguracao() {
        if (this.formRequerente.formTipoNegocio.tipoNegocio != null) {
            if (this.formRequerente.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Alteracao_De_Autonomo_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Dados_Cadastrais
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                        this.inicializacao();
                    })
            }
            else if (this.formRequerente.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa || this.formRequerente.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Alteracao_De_Empresa_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Dados_Cadastrais
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                        this.inicializacao();
                    })
            }
        }
    }

    montarObjetoProcesso(processo) {
        Object.assign(this.processo, processo);
        //this.formRequerente.servicos = processo.servicos;
    }

    inicializacao() {
        //this.formIdentificacaoNegocio.tipoEmpresa = this.formRequerente.formTipoNegocio.tipoNegocio.value;
        
        this.formIdentificacaoNegocio.inicializacaoFormulario();

        //this.formResumo.tipoEmpresa = this.formRequerente.formTipoNegocio.tipoNegocio.value;
        //this.formIdentificacaoNegocio.limpar();
        //this.formLocalizacao.limparEnderecoEmpresarial();
        //this.formLocalizacao.limparEnderecoCorrespondencia();
        //this.formAtividade.limpar();
        this.formRequerente.montarConfiguracao();
        this.atendimentoRequisito.inicializar();

    }

    inicializarRequisitos() {
        let atividades = "";
        this.protocolo.viabilidade.atividadesExercidas.forEach( (atividade: Atividade) => {
            if (this.formIdentificacaoNegocio.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo) {
                atividades += atividade.codigoInterno
            }
            else {
                atividades += atividade.codigo
            }
        })
        
        this.protocolo.viabilidade.atividadesNaoExercidas.forEach( (atividade: Atividade) => {
            if (this.formIdentificacaoNegocio.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo) {
                atividades += atividade.codigoInterno
            }
            else {
                atividades += atividade.codigo
            }
        })

        this.requisitoService
            .buscarParaAtendimentoOficio(this.processo.servicos[0].codigoAuxiliar, this.formIdentificacaoNegocio.tipoEmpresa, atividades)
            .pipe(take(1))
            .subscribe( requisitos => {
                this.atendimentoRequisito.preencher(requisitos, this.protocolo);
            })
    }

}