<!-- <div class="col-12 d-flex justify-content-center pt-3 pb-2 header-anexo">
    <h4 class="h3-anexo">
        <strong>GERENCIAMENTO DE ANEXOS</strong>
    </h4>
</div> -->
<div class="card" *ngIf="dados[0] == '' && dados[1] != '' && (dados[3] != 'ATENDIMENTO' && dados[3] != 'CONTRIBUINTE' && dados[3] != 'RESUMO')">
    <div class="card-header card-header-principal" >
        <div class="row col-12 justify-content-center align-items-center" >
            <strong>GERENCIAMENTO DE ANEXOS</strong>
        </div>
    </div>
    <div class="card-body" [class.p-0]="semCardHeader">
        <div class="row row-linha">
            <div class="col-2">
                {{ empresa.registro.tipoPessoa != 'F' ? 'Nome Empresarial' : 'Nome'}}:
            </div>
            <div class="col-10">
                {{ empresa.registro.nome }}
            </div>
        </div>
        <div class="row row-linha">
            <div class="col-2">
                {{ empresa.registro.tipoPessoa != 'F' ? 'CNPJ' : 'CPF'}}:
            </div>
            <div class="col-4">
                {{ 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj }).length ? 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj })[0].numero : ''
                }}
            </div>
            <div class="col-2">
                Data Registro:
            </div>
            <div class="col-4">
                {{ empresa.registro.data }}
            </div>
        </div>
        <div class="row row-linha">
            <div class="col-2">
                Insc. Mobiliária:
            </div>
            <div class="col-4">
                {{ 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Municipal }).length ? 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Municipal })[0].numero : ''
                }}
            </div>
            <div class="col-2">
                Data Abertura:
            </div>
            <div class="col-4">
                {{ 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Municipal }).length ? 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Municipal })[0].dataEmissao : ''
                }}
            </div>
        </div>
        <div class="row row-linha">
            <div class="col-2">
                {{ empresa.registro.tipoPessoa != 'F' ? 'Insc. Estadual' : 'RG'}}
            </div>
            <div class="col-4">
                {{ 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Estadual }).length ? 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Estadual })[0].numero : ''
                }}
            </div>
            <div class="col-2">
                {{ empresa.registro.tipoPessoa != 'F' ? 'Data Insc. Estadual' : 'Data RG'}}
            </div>
            <div class="col-4">
                {{ 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Estadual }).length ? 
                    (empresa.registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Estadual })[0].dataEmissao : ''
                }}
            </div>
        </div>
    </div>
</div>
<div class="card" *ngIf="dados[0] != '' && dados[3] != 'ATENDIMENTO' && dados[3] != 'RESUMO'">
    <div class="card-header card-header-principal" >
        <div class="row col-12 justify-content-center align-items-center" >
            <strong>GERENCIAMENTO DE ANEXOS</strong>
        </div>
    </div>
    <div class="row row-linha">
        <div class="col-2">
            Protocolo:
        </div>
        <div class="col-10">
            {{ protocoloAcompanhamento.protocolo }}
        </div>
    </div>
    <div class="row row-linha">
        <div class="col-2">
            Início:
        </div>
        <div class="col-4">
            {{ protocoloAcompanhamento.dataSituacao }}
        </div>
        <div class="col-2">
            Serviço:
        </div>
        <div class="col-4">
            {{ protocoloAcompanhamento.servicoDescricao }}
        </div>
    </div>
    <div class="row row-linha">
        <div class="col-2">
            Fase:
        </div>
        <div class="col-4">
            {{ protocoloAcompanhamento.faseDescricao }}
        </div>
        <div class="col-2">
            Situação:
        </div>
        <div class="col-4">
            {{ protocoloAcompanhamento.situacaoDescricao }}
        </div>
    </div>
</div>
<div *ngIf="dados[3] == 'ATENDIMENTO' || dados[3] == 'RESUMO'" class="card card-atendimento">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Anexo(s)</strong>
        </div>
    </div>
</div>
<input class="form-control" type="file" id="formFile" (change)="upload($event)">
<br>
<div *ngFor="let protocolo of listaDeProtocolos">
    <div class="mt-3" [class.card]="dados[3] != 'ATENDIMENTO' && dados[3] != 'CONTRIBUINTE' && dados[3] != 'RESUMO'"
        [class.card-atendimento]="dados[3] == 'ATENDIMENTO' || dados[3] == 'CONTRIBUINTE' || dados[3] == 'RESUMO'" >
        <div class="card-header card-header-principal" >
            <div class="row col-12 justify-content-end align-items-center" >
                <strong>{{ protocolo == '' ? 'INSCRIÇÃO: ' + dados[1] : 'PROTOCOLO: ' + protocolo }}</strong>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row header-atividades">
                <div class="row justify-content-center align-items-center col-4" >
                    <p class="card-text titulo-atividades">
                        <strong>Nome do Arquivo</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Orgão</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-3" >
                    <p class="card-text titulo-atividades">
                        <strong>Responsável</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Data</strong>
                    </p>
                </div>
                <div class="row col-1"></div>
            </div>
            <div *ngFor="let anexo of anexos | filtro: {campo: 'protocolo', valor: protocolo}; let i = index" 
                class="row row-linha justify-content-center align-items-center"
                [class.row-cinza]="i % 2 != 0" >
                <div class="row col-4">
                    <p class="card-text" 
                    [matTooltip]="anexo.nome"
                    matTooltipPosition="above">
                        {{ anexo.nome.length > 35 ? anexo.nome.substring(0, 35) + '...' : anexo.nome }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.orgao.nome }}
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.observacao }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.dataInicio }}
                    </p>
                </div>
                <div class="row col-1 justify-content-end align-items-center"
                    (click)="baixarArquivo(anexo.codigo, anexo.nome, anexo.extensao)">
                    <svg class="iconeGridDownload" id="informacoes-documentacoes-tab" >
                        <use attr.xlink:href="assets/sprite.svg#{{ 'download' }}"></use>
                    </svg> 
                </div>
                <div class="row col-1 justify-content-end align-items-center" title="Remover Anexo" *ngIf="cliente == '9999'"
                (click)="deletarArquivo(anexo.codigo)">
                <svg class="iconeFecharPopUp" id="informacoes-documentacoes-tab" title="Remover Anexo" matTooltip="Excluir">
                    <use attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"></use>
                </svg> 
            </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="dados[3] != 'RESUMO' && !listaDeProtocolos.size" 
    [class.card]="dados[3] != 'ATENDIMENTO' && dados[3] != 'CONTRIBUINTE'"
    [class.card-atendimento]="dados[3] == 'ATENDIMENTO' || dados[3] == 'CONTRIBUINTE'">
    <div class="card-body" [class.p-0]="semCardHeader">
        <p class="card-text text-center">
            Não foram encontrado(s) anexo(s).
        </p>
    </div>
</div>
<div *ngIf="dados[3] == 'RESUMO' && (!listaDeProtocolos.size && dados[0] == '')">
    <div class="mt-3 card-atendimento">
        <div class="card-header card-header-principal" >
            <div class="row col-12 justify-content-end align-items-center" >
                <strong>ANEXOS TEMPORÁRIOS</strong>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row header-atividades">
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Código</strong>
                    </p>
                </div>
                <div class="row justify-content-start align-items-center col-10" >
                    <p class="card-text titulo-atividades">
                        <strong>Nome do Arquivo</strong>
                    </p>
                </div>
                <div class="row col-1"></div>
            </div>
            <div *ngFor="let anexo of anexos; let i = index" 
                class="row row-linha justify-content-center align-items-center"
                [class.row-cinza]="i % 2 != 0" >
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.codigo }}
                    </p>
                </div>
                <div class="row col-9 justify-content-start align-items-center">
                    <p class="card-text">
                        {{ anexo.nome }}
                    </p>
                </div>
                <div class="row col-1 justify-content-end align-items-center"
                    (click)="baixarArquivo(anexo.codigo, anexo.nome, anexo.extensao)">
                    <svg class="iconeGridDownload" id="informacoes-documentacoes-tab" >
                        <use attr.xlink:href="assets/sprite.svg#{{ 'download' }}"></use>
                    </svg> 
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="dados[3] == 'RESUMO' && !anexos.length && !listaDeProtocolos.size" 
    class="card-atendimento">
    <div class="card-body" [class.p-0]="semCardHeader">
        <p class="card-text text-center">
            Não foram encontrado(s) anexo(s).
        </p>
    </div>
</div>
