<div class="justify-content-md-end">
  <div class="row col-12 top-atendimento mt-2">
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3">
      <div class="row col-6 justify-content-start align-items-center">
          <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
      </div>
      <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
        <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
      </div>
    </div>
  </div>
</div>
<form name="formulario" 
  [formGroup]="formulario" 
  class="justify-content-center align-items-center"
>
<div class="container-principal-atendimento mt-3 mb-5">
  <app-alert type="danger"
      *ngIf="emitirErro" >
      <div [innerHtml]="mensagemErro"></div>
  </app-alert>
    <div class="card">
      <div class="card-header">
        <div class="col-12">
          <strong>{{ acaoAtendimento == acaoAtendimentoAuxiliar.Alterar ? 'Editar Pergunta' : 'Cadastrar Pergunta' }}</strong>
        </div>
      </div>
      <div class="card-body p-0 mb-2">
        <div class="row mt-3">
          <div class="form-group col-10">
            <label>Descrição</label>
            <input class="form-control form-control-sm" 
              formControlName="nome" 
              placeholder="Descrição da pergunta a ser criada" 
              id="nome" 
              name="nome"
              type="text" />
            <div class="mb-1" *ngIf="nome.invalid && nome.touched">
              <span class="erro" *ngIf="nome.errors.required">Campo obrigatório</span>
            </div>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <mat-radio-group 
              formControlName="status" 
              aria-label="Status">
                <mat-radio-button value="1">
                    <strong>Ativo</strong>
                </mat-radio-button><br>
                <mat-radio-button value="2">
                  <strong>Inativo</strong>
              </mat-radio-button>
            </mat-radio-group> 
          </div>
        </div>
        <div class="row mt-3">
          <div class="form-group col-12">
            <label>Respostas</label>
            <input class="form-control form-control-sm" 
              formControlName="respostas" 
              placeholder="Respostas da pergunta a ser criada" 
              id="respostas" 
              name="respostas"
              type="text" 
              [disabled]="processoSelect.value != 3 ? 'disabled' : ''"/>
            <div class="mb-1" *ngIf="respostas.invalid && respostas.touched">
              <span class="erro" *ngIf="respostas.errors.required">Campo obrigatório</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="form-group col-5">
            <label>Característica</label>
            <select 
              #caracteristicaDoQuestionarioSelect
              name="caracteristicaDoQuestionario" 
              formControlName="caracteristicaDoQuestionario" 
              id="caracteristicaDoQuestionario" 
              class="form-control">
              <option value=""></option>
              <option *ngFor="let perguntaCaracteristica of perguntaCaracteristicas" 
                value="{{ perguntaCaracteristica.codigo }}">
                {{ perguntaCaracteristica.codigo }} - {{ perguntaCaracteristica.descricao }}
              </option>
            </select>
          </div>
          <div class="form-group col-3">
            <label>Processo</label>
            <select 
              #processoSelect
              name="processo" 
              formControlName="processo" 
              id="processo" 
              class="form-control">
              <option value=""></option>
              <option *ngFor="let tipo of tipoDePergunta" 
                value="{{ tipo }}">
                {{ tipoDePerguntaDescricao[tipo] }}
              </option>
            </select>
          </div>
          <div class="form-group col-2">
            <label>Tipo</label>
            <select 
              name="tipo" 
              formControlName="tipo" 
              id="tipo" 
              class="form-control">
              <option value=""></option>
              <option *ngFor="let tipo of tipoDeCampoPergunta" 
                value="{{ tipo }}">
                {{ tipoDeCampoPerguntaDescricao[tipo] }}
              </option>
            </select>
          </div>
          <div class="form-group col-2">
            <label>Obrigatório</label>
            <select 
              name="obrigatorio" 
              formControlName="obrigatorio" 
              id="obrigatorio" 
              class="form-control">
              <option value=""></option>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>
        </div>
        <!----<div class="row mt-3">
          <div class="form-group col-4">
            <label>Atividade</label>
            <input class="form-control form-control-sm" 
              id="atividade" 
              name="atividade"
              type="text" />
          </div>
        </div>--->
      </div>
      <div class="card-header">
        <div class="col-12">
          <strong>Vínculos</strong>
        </div>
      </div>
      <div class="card-body pt-3" *ngIf="obrigatorio.value != '1'">
        <div class="row col-12 row-linha pb-4" >
          <p class="card-text">
            Caro Auditor, nos botões abaixo você poderá configurar para quais atividades essa pergunta se tornará obrigatória.<br>
          </p>
        </div>
        <div class="row col-12 mb-4">
          <div class="row col-2 justify-content-center align-items-center"></div>
          <div class="row col-4 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-5" (click)="openDialog(tipoEmpresa.Empresa)">Empresa / Mei</button>
          </div>
          <div class="row col-4 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-5" (click)="openDialog(tipoEmpresa.Autonomo)">Autônomo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-principal-atendimento mt-3 mb-5" *ngIf="acaoAtendimento == acaoAtendimentoAuxiliar.Alterar">
    <div class="card">
      <div class="card-header">
        <div class="col-12">
          <strong>Vínculos por Arquivo</strong>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="row col-12 row-linha pb-4" >
          <p class="card-text">
            Caro Auditor, no botão abaixo você poderá configurar para quais atividades essa pergunta terá vínculo.<br>
          </p>
        </div>
        <div class="row col-12">
          <div class="row col-12 justify-content-center align-items-center">
          <button type="button" class="btn btn-success col-2" (click)="openDialogVinculo(tipoDeVinculo.Pergunta_Cnae)">Vínculos</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</form>



