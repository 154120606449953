import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

import { Grid } from "src/app/model/grid";
import { GridProviderPaginacao } from "src/app/components/grid/grid-provider-paginacao";

import { MatDialog } from "@angular/material/dialog";
import { GridFiltro } from "src/app/model/gridFiltro";
import { formatToCPFOrCNPJ } from 'brazilian-values';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from "sweetalert2";
import { TaxaLancamentoParcela } from "src/app/model/taxaLancamentoParcela";
import { Constantes } from "src/app/model/constantes";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Console } from 'console';
import { ArquivoService } from "src/app/services/arquivo/arquivo.service";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RequisitoService } from "src/app/services/requisito/requisito.service";

@Component({
  selector: 'app-grid-arquivos-vinculos',
  templateUrl: './grid-arquivos-vinculos.component.html',
  styleUrls: ['./grid-arquivos-vinculos.component.scss'],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class GridArquivosVinculosComponent implements OnInit {
  
  grid: Grid;
  gridArquivoVinculo: Grid;
  formData: FormData;
  private unsubscribe = new Subject<void>();
  currentUser: any;
  files: Set<File>;

  @Input() titulo: string;
  @Input() tipo: string;
  @Input() codigo: string;
  
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ["codigo","nome","nomeLogin","dataInicio","dataFim","observacao","qtdeDeLinhas","qtdeDeLinhasVerificadas","html"];
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() exercicio: string;
  @Input() codigoLancamento: string;
  @Output() acaoMenuAtendimento = new EventEmitter();

  acaoAtendimento = Constantes.ACAO_ATENDIMENTO;

  mensagemDeBuscaArquivo: string = "Não existe arquivo disponível.";
  mensagemDeBusca: string = "Não existem arquivo(s) enviado(s).";

  constructor(
    private mascara: MascaraPipe,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private arquivoService: ArquivoService,
    private requisitoService: RequisitoService) { 
      this.gridArquivoVinculo = new Grid();
      this.gridArquivoVinculo.filtro.push(new GridFiltro());
      
      this.grid = new Grid();
      this.grid.filtro.push(new GridFiltro());

      this.currentUser = this.authenticationService.currentUserValue;

    }

  ngOnInit(): void {

    this.requisitoService
        .buscarVinculosPorArquivo(this.tipo, this.codigo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.gridArquivoVinculo.dados = retorno;   
          this.atualizarTabela();
        });
      
  }

  atualizarTabela() {

    this.dataSource = new MatTableDataSource(this.gridArquivoVinculo.dados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
    //this.sort.active = "vencimento";
    //this.sort.direction = <SortDirection>"ASC".toLowerCase();

    this.dataSource.sort = this.sort;
  }

  formatToCPFOrCNPJ(valor) {
    return formatToCPFOrCNPJ(valor.toString());
  }

  formatToBRL(valor) {
      return this.mascara.transform(valor, 11);
  }

  aplicarFiltro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  ordenacaoGrid(data, sort) {
    if (sort.active == null)
        return data;

    let campo = sort.active;
    
    if (campo == "adicionado") {
      data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "vencimento") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "inscricao") {
        data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof("0")) {
        data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof(0)) {
        data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    
    return data;
  }

  atualizarGrid(){
    this.requisitoService
        .buscarVinculosPorArquivo(this.tipo, this.codigo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.gridArquivoVinculo.dados = retorno;   
          this.atualizarTabela();
        });
  }

  upload(event) {
   if (this.arquivos(event)) {
      console.log(this.formData);
      this.arquivoService
        .uploadArquivoDeVinculos(this.formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe (retorno => {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
                //this.router.navigate([retorno.redirecionamento]);
            }
        })

        this.requisitoService
        .buscarVinculosPorArquivo(this.tipo, this.codigo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.gridArquivoVinculo.dados = retorno;   
          this.atualizarTabela();
        });

        });
    }

    (event.target as HTMLInputElement).value = "";
    
  }

  arquivos(event) {
    this.formData = new FormData();
    const arquivosSelecionados = <FileList>event.srcElement.files;
    this.files = new Set();
    for (let i = 0; i < arquivosSelecionados.length; i++) {
      if (arquivosSelecionados[i].name.length > 95) {
        Swal.fire({
          title: "Não Enviado",
          html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível enviar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O nome do arquivo está muito grande, favor diminuir.`,
          icon: "error",
        });

        return false;
      }
      if (arquivosSelecionados[i].size > 5242880) {
        Swal.fire({
          title: "Não Enviado",
          html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível enviar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O tamanho do arquivo está muito grande, favor diminuir.`,
          icon: "error",
        });

        return false;
      }
      this.files.add(arquivosSelecionados[i]);
      this.formData.append('file', arquivosSelecionados[i], arquivosSelecionados[i].name);
      this.formData.append('fileName', arquivosSelecionados[i].name);

      this.formData.append('tipo', this.tipo);
      this.formData.append('parametro_1', this.codigo);
    }

    return true;
  }

  downloadPorCodigo(codigo,nome){
    this.arquivoService.downloadArquivoDeVinculosPorCodigo(codigo,this.tipo,this.codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, nome);
        });
  }

  downloadVinculosAtivos(){
    this.arquivoService.downloadArquivoDeVinculosAtivos(this.tipo,this.codigo)
    .subscribe((res: any) => {
        this.arquivoService.handleFile(res, 'vinculos_ativos');
    });
  }

  acaoImprimirComprovante(row) {
    this.acaoMenuAtendimento.emit({ comprovante: row });
  }

  acaoInformacaoBaixa(row) {
    this.acaoMenuAtendimento.emit({ informacoesBaixa: row, comprovante: row });
  }

  acaoMemoriaDeCalculoParcela(row) {
    this.acaoMenuAtendimento.emit({ memoriaDeCalculoParcela: row });
  }


}
