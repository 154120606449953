<router-outlet><app-loader></app-loader>


<div *ngIf="authenticationService.currentUserValue" 
    class="open-button text-center row justify-content-center align-items-center" 
    (click)="abrirChat()">
    <span>
      <svg class="iconeChat">
          <use
            attr.xlink:href="/assets/sprite.svg#{{ 'chat' }}"
          ></use>
      </svg>
  </span>
</div>
</router-outlet>
