<div class="d-flex justify-content-center align-items-center flex-column p-3"
     style="height: 100vh;">
  <div>
    <h2 class="primaryColor">Página não encontrada.</h2>
  </div>
  <h5>Este endereço não existe, foi removido ou está fora do ar.</h5>
  <div>
    <h2 class="position-absolute"
        style="right: 5vw; bottom: 3vw;">erro 404</h2>
  </div>

  <div>
    <a href="javascript:void(0)"
       (click)="backLastPage()"
       class="btn btn-primary mt-4 px-5"
       role="button"
       aria-pressed="true">
      Voltar
    </a>
  </div>
</div>