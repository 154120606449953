import { OnInit, Component, Input } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Protocolo } from "src/app/model/protocolo";
import { formatToCPFOrCNPJ, formatToDateTime, formatToCNPJ, formatToPhone } from "brazilian-values";
import { FormValidator } from "src/app/components/validators/form-validator";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { Documento } from "src/app/model/documento";
import { Constantes } from "src/app/model/constantes";
import { Telefone } from "src/app/model/telefone";
import { MaskPipe } from "ngx-mask";
import { Empresa } from "src/app/model/empresa";
import { ActivatedRoute } from "@angular/router";
import { Escolaridade } from "src/app/model/escolaridade";
import { OrgaoDeClasse } from "src/app/model/orgaoDeClasse";
import { LoginService } from "src/app/services/login/login.service";
import { Configuracao } from "src/app/model/configuracao";
import { Login } from "src/app/model/login";
import { take, filter } from 'rxjs/operators';
import Swal from "sweetalert2";
import { Critica } from '../../../model/critica';
import { EmpresaService } from '../../../services/empresa/empresa.service';

@Component({
    selector: "app-form-identificacao-negocio",
    templateUrl: "./form-identificacao-negocio.component.html",
    styleUrls: ["./form-identificacao-negocio.component.scss"]
})
export class FormIdentificacaoNegocioComponent implements OnInit {
    
    @Input() documentos: Array<Documento>;
    
    tipoEmpresa: string;
    protocolo: Protocolo;
    formulario: FormGroup;
    tiposPorteEmpresa = Constantes.TIPOS_PORTE_EMPRESA; 
    estados = Constantes.ESTADOS;
    configuracao: Configuracao;

    emitirCritica: boolean;
    mensagemCritica: string;
    emitirErro: boolean;
    mensagemErro: string;
    emitirErroInscricaoMunicipal: boolean;
    mensagemErroInscricaoMunicipal: string;
    permitirNovaInscricaoMunicipal: boolean = false;

    listaEscolaridade: Array<Escolaridade>;
    orgaoDeClasse: Array<OrgaoDeClasse>;
    exibirDadosDeClasse = false;
    exibirDadosDeClasseDetalhe = false;
    inscricaoMunicipalValida: boolean = false;
    dataAtual: string = new Date().toLocaleDateString("pt-br", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase();
    
    constructor(
        private formBuilder: FormBuilder,
        private mascara: MascaraPipe,
        private loginService: LoginService,
        private empresaService: EmpresaService,
        private activatedRoute: ActivatedRoute,
        private mask: MaskPipe) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;

        this.listaEscolaridade = new Array<Escolaridade>();
        this.orgaoDeClasse = new Array<OrgaoDeClasse>();

        this.protocolo = new Protocolo();
        this.documentos = new Array<Documento>();

        this.formulario = this.formBuilder.group({
            nomeEmpresarial: ['', [Validators.required]],
            nomeFantasia: [''],
            email: ['', [Validators.required, Validators.email]],
            site: [''],
            telefoneFixo: [''],
            telefoneComercial: [''],
            telefoneCelular: ['', [Validators.required, Validators.minLength(15)]],
            capitalSocial: ['0,00', [Validators.required, Validators.maxLength(18)]],
            documento: ['', [Validators.required]],
            nire: ['', [Validators.required]],
            dataNire: ['', [Validators.required, FormValidator.dataValida(), FormValidator.dataMaior("dataUltimaAlteracao")]],
            dataUltimaAlteracao: ['', [FormValidator.dataValida(), FormValidator.dataMenor("dataCnpj"), FormValidator.dataMenor("dataNire")]],
            porte: ['', [Validators.required]],
            cnpj: ['', [Validators.required, Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
            dataCnpj: ['', [Validators.required, FormValidator.dataValida(), FormValidator.dataMaior("dataUltimaAlteracao")]],
            inscricaoEstadualIsento: [''],
            inscricaoEstadual: ['', [Validators.required, Validators.maxLength(18)]],
            dataInscricaoEstadual: ['', [Validators.required, FormValidator.dataValida()]],
            inscricaoMunicipal: ['', { disable: true }],
            dataInscricaoMunicipal: ['', { disable: true }, [FormValidator.dataValida()]],
            tipoEstabelecimento: ["M", [Validators.required]],
            cnpjFilial: ["", [Validators.maxLength(18)]],
            objetoSocial: ["", [Validators.required, Validators.maxLength(7000)]],
           
            cpfAutonomo: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14), FormValidator.cpfCnpjValido()]],
            nomeAutonomo: ['', [Validators.required]],
            nascimentoAutonomo: ['', [Validators.required, FormValidator.dataValida(), FormValidator.dataMaiorNascimento("dataEmissao")]],
            rgAutonomo: ['', [Validators.required]],
            dataEmissao: ['', [Validators.required, FormValidator.dataValida(), FormValidator.dataMenorNascimento("nascimentoAutonomo")]],
            uf: ['', [Validators.required]],
            emissor: ['', [Validators.required]],
            sexo: ['', [Validators.required]],
            escolaridade: ['', [Validators.required]],
            classe: ['', [Validators.required]],
            ufRegistro: ['', [Validators.required]],
            numeroRegistro: ['', [Validators.required]],
            dataRegistro: ['', [Validators.required, FormValidator.dataValida()]],
        });

        this.emitirCritica = false;
        this.mensagemCritica = "";
        this.emitirErro = false;
        this.mensagemErro = "";

    }

    ngOnInit(): void {
        this.inicializacaoFormulario();
    }

    inicializacaoFormulario() {


        console.log(this.tipoEmpresa);
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){

            
            this.nomeEmpresarial.clearValidators();
            this.nomeFantasia.clearValidators();
            this.email.clearValidators();
            this.site.clearValidators();
            this.telefoneFixo.clearValidators();
            this.telefoneComercial.clearValidators();
            this.telefoneCelular.clearValidators();
            this.capitalSocial.clearValidators();
            this.documento.clearValidators();
            this.nire.clearValidators();
            this.dataNire.clearValidators();
            this.dataUltimaAlteracao.clearValidators();
            this.porte.clearValidators();
            this.cnpj.clearValidators();
            this.dataCnpj.clearValidators();
            this.inscricaoEstadual.clearValidators();
            this.dataInscricaoEstadual.clearValidators();
            this.inscricaoMunicipal.clearValidators();
            this.dataInscricaoMunicipal.clearValidators();
            this.tipoEstabelecimento.clearValidators();
            this.objetoSocial.clearValidators();
            this.classe.clearValidators();
            this.cnpjFilial.clearValidators();

            this.nomeEmpresarial.updateValueAndValidity();
            this.nomeFantasia.updateValueAndValidity();
            this.email.updateValueAndValidity();
            this.site.updateValueAndValidity();
            this.telefoneFixo.updateValueAndValidity();
            this.telefoneComercial.updateValueAndValidity();
            this.telefoneCelular.updateValueAndValidity();
            this.capitalSocial.updateValueAndValidity();
            this.documento.updateValueAndValidity();
            this.nire.updateValueAndValidity();
            this.dataNire.updateValueAndValidity();
            this.dataUltimaAlteracao.updateValueAndValidity();
            this.porte.updateValueAndValidity();
            this.cnpj.updateValueAndValidity();
            this.dataCnpj.updateValueAndValidity();
            this.inscricaoEstadual.updateValueAndValidity();
            this.dataInscricaoEstadual.updateValueAndValidity();
            this.inscricaoMunicipal.updateValueAndValidity();
            this.dataInscricaoMunicipal.updateValueAndValidity();
            this.tipoEstabelecimento.updateValueAndValidity();
            this.objetoSocial.updateValueAndValidity();
            this.classe.updateValueAndValidity();
            this.cnpjFilial.updateValueAndValidity();


            this.listaEscolaridade = this.configuracao.escolaridades;
            this.orgaoDeClasse = this.configuracao.orgaoDeClasses;


        }
        else if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa || 
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei || 
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa || 
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa){
            this.cpfAutonomo.clearValidators();
            this.nomeAutonomo.clearValidators();
            this.nascimentoAutonomo.clearValidators();
            this.rgAutonomo.clearValidators();
            this.dataEmissao.clearValidators();
            this.uf.clearValidators();
            this.emissor.clearValidators();
            this.sexo.clearValidators();
            this.escolaridade.clearValidators();
            this.classe.clearValidators();
            this.ufRegistro.clearValidators();
            this.numeroRegistro.clearValidators();
            this.dataRegistro.clearValidators();
            
            this.cpfAutonomo.updateValueAndValidity();
            this.nomeAutonomo.updateValueAndValidity();
            this.nascimentoAutonomo.updateValueAndValidity();
            this.rgAutonomo.updateValueAndValidity();
            this.dataEmissao.updateValueAndValidity();
            this.uf.updateValueAndValidity();
            this.emissor.updateValueAndValidity();
            this.sexo.updateValueAndValidity();
            this.escolaridade.updateValueAndValidity();
            this.classe.updateValueAndValidity();
            this.ufRegistro.updateValueAndValidity();
            this.numeroRegistro.updateValueAndValidity();
            this.dataRegistro.updateValueAndValidity();

            if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa || 
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei){
                this.telefoneCelular.clearValidators();
                this.telefoneCelular.updateValueAndValidity();
                this.porte.clearValidators();
                this.porte.updateValueAndValidity();
                this.nire.clearValidators();
                this.nire.updateValueAndValidity();
                this.documento.clearValidators();
                this.documento.updateValueAndValidity();
                this.dataUltimaAlteracao.clearValidators();
                this.dataUltimaAlteracao.updateValueAndValidity();
                this.dataNire.clearValidators();
                this.dataNire.updateValueAndValidity();    
                this.objetoSocial.clearValidators();
                this.objetoSocial.updateValueAndValidity();
            }
            
            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei || (this.protocolo.registro.orgao == '2' && this.protocolo.registro.naturezaJuridica.codigo == '308-5') )   {
                this.nire.clearValidators();
                this.dataNire.clearValidators();

                this.dataNire.setValidators(FormValidator.dataValida());

                this.nire.updateValueAndValidity();
                this.dataNire.updateValueAndValidity();
            }        
        }
        else if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){

                    this.nomeFantasia.clearValidators();
                    this.capitalSocial.clearValidators();
                    this.documento.clearValidators();
                    this.nire.clearValidators();
                    this.dataNire.clearValidators();
                    this.dataUltimaAlteracao.clearValidators();
                    this.porte.clearValidators();
                    this.cnpj.clearValidators();
                    this.dataCnpj.clearValidators();
                    this.inscricaoEstadual.clearValidators();
                    this.dataInscricaoEstadual.clearValidators();
                    this.inscricaoMunicipal.clearValidators();
                    this.dataInscricaoMunicipal.clearValidators();
                    this.tipoEstabelecimento.clearValidators();
                    this.objetoSocial.clearValidators();
                    this.cpfAutonomo.clearValidators();
                    this.nomeAutonomo.clearValidators();
                    this.nascimentoAutonomo.clearValidators();
                    this.rgAutonomo.clearValidators();
                    this.dataEmissao.clearValidators();
                    this.uf.clearValidators();
                    this.emissor.clearValidators();
                    this.sexo.clearValidators();
                    this.escolaridade.clearValidators();
                    this.classe.clearValidators();
                    this.ufRegistro.clearValidators();
                    this.numeroRegistro.clearValidators();
                    this.dataRegistro.clearValidators();
                    
                    this.nomeFantasia.updateValueAndValidity();
                    this.capitalSocial.updateValueAndValidity();
                    this.documento.updateValueAndValidity();
                    this.nire.updateValueAndValidity();
                    this.dataNire.updateValueAndValidity();
                    this.dataUltimaAlteracao.updateValueAndValidity();
                    this.porte.updateValueAndValidity();
                    this.cnpj.updateValueAndValidity();
                    this.dataCnpj.updateValueAndValidity();
                    this.inscricaoEstadual.updateValueAndValidity();
                    this.dataInscricaoEstadual.updateValueAndValidity();
                    this.inscricaoMunicipal.updateValueAndValidity();
                    this.dataInscricaoMunicipal.updateValueAndValidity();
                    this.tipoEstabelecimento.updateValueAndValidity();
                    this.objetoSocial.updateValueAndValidity();
                    this.cpfAutonomo.updateValueAndValidity();
                    this.nomeAutonomo.updateValueAndValidity();
                    this.nascimentoAutonomo.updateValueAndValidity();
                    this.rgAutonomo.updateValueAndValidity();
                    this.dataEmissao.updateValueAndValidity();
                    this.uf.updateValueAndValidity();
                    this.emissor.updateValueAndValidity();
                    this.sexo.updateValueAndValidity();
                    this.escolaridade.updateValueAndValidity();
                    this.classe.updateValueAndValidity();
                    this.ufRegistro.updateValueAndValidity();
                    this.numeroRegistro.updateValueAndValidity();
                    this.dataRegistro.updateValueAndValidity();

                    this.porte.clearValidators();
                    this.porte.updateValueAndValidity();
                    this.nire.clearValidators();
                    this.nire.updateValueAndValidity();
                    this.documento.clearValidators();
                    this.documento.updateValueAndValidity();
                    this.dataUltimaAlteracao.clearValidators();
                    this.dataUltimaAlteracao.updateValueAndValidity();
                    this.dataNire.clearValidators();
                    this.dataNire.updateValueAndValidity();    
                    this.objetoSocial.clearValidators();
                    this.objetoSocial.updateValueAndValidity();
        
                    //this.listaEscolaridade = this.configuracao.escolaridades;
                    //this.orgaoDeClasse = this.configuracao.orgaoDeClasses;


        }
    }

    limpar(){
        this.nomeEmpresarial.reset();
        this.nomeEmpresarial.setValue("");
        this.nomeFantasia.reset();
        this.nomeFantasia.setValue("");
        this.email.reset();
        this.email.setValue("");
        this.site.reset();
        this.site.setValue("");
        this.telefoneFixo.reset();
        this.telefoneFixo.setValue("");
        this.telefoneComercial.reset();
        this.telefoneComercial.setValue("");
        this.telefoneCelular.reset();
        this.telefoneCelular.setValue("");
        this.cnpj.reset();
        this.cnpj.setValue("");
        this.dataCnpj.reset();
        this.dataCnpj.setValue("");
        this.inscricaoEstadual.reset();
        this.inscricaoEstadual.setValue("");
        this.dataInscricaoEstadual.reset();
        this.dataInscricaoEstadual.setValue("");
        this.inscricaoMunicipal.reset();
        this.inscricaoMunicipal.setValue("");
        this.dataInscricaoMunicipal.reset();
        this.dataInscricaoMunicipal.setValue("");
        this.tipoEstabelecimento.reset();
        this.tipoEstabelecimento.setValue("M");
        this.cnpjFilial.reset();
        this.cnpjFilial.setValue(null);
        this.objetoSocial.reset();
        this.objetoSocial.setValue("");

        this.cpfAutonomo.reset();
        this.cpfAutonomo.setValue("");
        this.nomeAutonomo.reset();
        this.nomeAutonomo.setValue("");
        this.nascimentoAutonomo.reset();
        this.nascimentoAutonomo.setValue("");
        this.rgAutonomo.reset();
        this.rgAutonomo.setValue("");
        this.dataEmissao.reset();
        this.dataEmissao.setValue("");
        this.uf.reset();
        this.uf.setValue("");
        this.emissor.reset();
        this.emissor.setValue("");
        this.sexo.reset();
        this.sexo.setValue("");
        this.escolaridade.reset();
        this.escolaridade.setValue("");

    }
    
    formatToCPFOrCNPJ(valor) {
        return formatToCPFOrCNPJ(valor.toString());
    }

    formatToCPF(valor) {
        this.cpfAutonomo.setValue(formatToCPFOrCNPJ(valor));
    }

    formatToCNPJ(valor) {
        this.cnpj.setValue(formatToCNPJ(valor));
    }
    
    formatToCNPJFilial(valor) {
        this.cnpjFilial.setValue(formatToCNPJ(valor));
    }

    formatToBRL(valor) {
        this.capitalSocial.setValue(this.mascara.transform(valor, 11));
    }
    
    formatToDateTime(valor) {
        if (valor == null || valor == "")
            return "";
        
        return formatToDateTime(new Date(valor));
    }
    
    formatToPhone(objeto) {
      objeto.value = formatToPhone(objeto.value);
    }

    acaoCheckBoxInscricaoIsento(valor) {
        if (valor.checked) {
            this.inscricaoEstadual.setValue("ISENTO");
            this.inscricaoEstadual.disable();
            this.inscricaoEstadual.clearValidators();
            this.dataInscricaoEstadual.disable();
            this.dataInscricaoEstadual.clearValidators();

        }
        else {
            this.inscricaoEstadual.setValue("");
            this.inscricaoEstadual.enable();
            this.inscricaoEstadual.setValidators(Validators.required);
            this.dataInscricaoEstadual.enable();
            this.dataInscricaoEstadual.setValidators(Validators.required);
        }
    }

    acaoCheckBoxNovaInscricaoMunicipal(valor) {
        this.inscricaoMunicipal.setValue("");
        this.dataInscricaoMunicipal.setValue("");

        if (valor.checked) {
            this.inscricaoMunicipalValida = false;
            this.inscricaoMunicipal.disable();
            this.dataInscricaoMunicipal.disable();
            this.inscricaoMunicipal.clearValidators();
            this.dataInscricaoMunicipal.clearValidators();
        }
        else {
            this.inscricaoMunicipal.enable();
            this.dataInscricaoMunicipal.enable();
            this.inscricaoMunicipal.setValidators(Validators.required);
            this.dataInscricaoMunicipal.setValidators(Validators.required);
        }
    }

    acaoRadioTipoEstabelecimento() {
        this.cnpjFilial.setValidators([Validators.required, FormValidator.cpfCnpjValido()]);
        if (this.tipoEstabelecimento.value === "M") {
            this.cnpjFilial.setValue("");
            this.cnpjFilial.clearValidators();
        }
        this.cnpjFilial.updateValueAndValidity();
    }

    verificaAutonomo(){

        if (this.cpfAutonomo.invalid)
            return false;
                
        let login = new Login();
        login.login = this.cpfAutonomo.value.replace(/\D/g,"");

        if(this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo && this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa && this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei){
            this.loginService
            .buscarPorLogin(login)
            .pipe(take(1))
            .subscribe( (usuario: Login) => { 
                if (!usuario.login) {
                    return false;
                }
                Swal.fire("", "Você está solicitando a própria inscrição, os dados serão preenchidos automaticamente.", "info");

                this.usarDadosRequerente(true);
            },
            (err) => {
                console.log('err', err);
                Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
        );
        }

    }

    verificaInscricaoMunicipal() {
        this.empresaService
            .validarInscricaoMunicipal(localStorage.getItem('pId'), this.inscricaoMunicipal.value, this.cnpj.value.replace(/\D/g, ""))
            .pipe(take(1))
            .subscribe( (empresa: Empresa) => {
                if (empresa.status != Constantes.STATUS_EMPRESA.Ativa) {
                    Swal.fire('', 'Inscrição municipal inválida.', 'warning');
                    this.inscricaoMunicipal.reset();
                    return false;
                }
                this.inscricaoMunicipalValida = true;
                },
                (err) => {
                    console.log('err', err);
                }
        );
    }

    exibeDadosDeClasse(value: number){
        this.classe.enable();
        //this.ufRegistro.enable();
        //this.numeroRegistro.enable();
        //this.dataRegistro.enable();
        this.exibirDadosDeClasse = true;
        if(value < 2){
            this.classe.disable();
            this.ufRegistro.disable();
            this.numeroRegistro.disable();
            this.dataRegistro.disable();
            this.classe.reset();
            this.ufRegistro.reset();
            this.numeroRegistro.reset();
            this.dataRegistro.reset();
            this.exibirDadosDeClasse = false;
            this.exibirDadosDeClasseDetalhe = false;
        }
    }

    exibeDadosDeClasseDetalhe(value: number){  
        this.ufRegistro.enable();
        this.numeroRegistro.enable();
        this.dataRegistro.enable();
        this.exibirDadosDeClasseDetalhe = true;
        if(value == 0 || value == 48){
            this.ufRegistro.disable();
            this.numeroRegistro.disable();
            this.dataRegistro.disable();
            this.ufRegistro.reset();
            this.numeroRegistro.reset();
            this.dataRegistro.reset();
            this.exibirDadosDeClasseDetalhe = false;
        }
    }

    usarDadosRequerente(value: boolean){
        if(value == true){
  
            let login = new Login();
            login.login = parseInt(localStorage.getItem("userId").replace(/\D/g,""));

            this.loginService
                .buscarPorLogin(login)
                .pipe(take(1))
                .subscribe( (usuario: Login) => { 
                    if (!usuario.login) {
                        return false;
                    }
                    
                    this.preencherCamposAutonomo(usuario);
                },
                (err) => {
                    console.log('err', err);
                    
                }
            );
        }else{
            this.cpfAutonomo.reset();
            this.nomeAutonomo.reset();
            this.nascimentoAutonomo.reset();
            this.rgAutonomo.reset();
            this.dataEmissao.reset();
            this.uf.reset();
            this.emissor.reset();
            this.sexo.reset();
        }
    }

    preencherCamposRecadastro(empresa){
        empresa.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj && 
                (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei)) {
                this.cnpj.setValue(formatToCPFOrCNPJ(documento.numero.toString()));
                this.dataCnpj.setValue(documento.dataEmissao);
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj && this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                this.cnpj.setValue(formatToCPFOrCNPJ(documento.numero.toString()));
                this.dataCnpj.setValue(documento.dataEmissao);
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                this.inscricaoMunicipal.setValue(documento.numero);
                this.dataInscricaoMunicipal.setValue(documento.dataEmissao);
            }
            
        });
    }

    preencherCampos(empresa) {

        if (this.tipoEmpresa == 'A' ){
            this.nomeAutonomo.setValue(empresa.registro.nome);
            this.nascimentoAutonomo.setValue(empresa.registro.nascimento);

            empresa.registro.documentos.forEach( documento => {
                if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cpf){
                    this.cpfAutonomo.setValue(documento.numero);
                }
                if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Rg){
                    this.rgAutonomo.setValue(documento.numero);
                    this.dataEmissao.setValue(documento.dataEmissao);
                    this.uf.setValue(documento.estadoEmissor);
                    this.emissor.setValue(documento.orgaoEmissor);
                }
                if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.OrgaoDeClasse){
                    this.classe.setValue(documento.orgaoDeClasse.codigo);
                    this.ufRegistro.setValue(documento.estadoEmissor);
                    this.numeroRegistro.setValue(documento.numero);
                    this.dataRegistro.setValue(documento.dataEmissao);
                    if (this.classe.value != ""){
                        this.exibirDadosDeClasse = true;
                        this.exibirDadosDeClasseDetalhe = true;
                    }
                }                
            })
            this.sexo.setValue(empresa.registro.sexo);
            this.escolaridade.setValue(empresa.registro.escolaridade.codigo);
            
        }
        
        this.nomeEmpresarial.setValue(empresa.fic.nomeEmpresa);
        this.nomeFantasia.setValue(empresa.fic.nomeFantasia);
        this.email.setValue(empresa.fic.emails.length ?  empresa.fic.emails[0] : "");
        this.site.setValue(empresa.fic.sites.length ?  empresa.fic.sites[0] : "");

        empresa.fic.telefones.forEach( telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                this.telefoneFixo.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                this.telefoneComercial.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                this.telefoneCelular.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
        });
        
        this.nire.setValue(empresa.registro.numero);
        this.dataNire.setValue(empresa.registro.data);
        
        this.documentos.forEach(documento => {
            if (documento.codigo == empresa.registro.tipo) {
                this.documento.setValue(empresa.registro.tipo);
            }
        });
        
        this.tiposPorteEmpresa.forEach(porteEmpresa => {
            if (porteEmpresa.value == empresa.registro.porte) {
                this.porte.setValue(empresa.registro.porte);
            }
        });

        this.capitalSocial.setValue(this.mascara.transform(empresa.registro.capitalSocial, 11));
        
        if (this.capitalSocial.disabled) {
            this.capitalSocial.setValue("0,00");
        }

        this.dataUltimaAlteracao.setValue(empresa.registro.ultimaAlteracao);
    
        empresa.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj) {
                this.cnpj.setValue(formatToCPFOrCNPJ(documento.numero.toString()));
                this.dataCnpj.setValue(documento.dataEmissao);
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Estadual) {
                this.inscricaoEstadual.setValue(documento.numero);
                this.dataInscricaoEstadual.setValue(documento.dataEmissao);
                if (documento.numero == "ISENTO") {
                    this.inscricaoEstadual.disable();
                    this.dataInscricaoEstadual.disable();
                }
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                this.inscricaoMunicipal.setValue(documento.numero);
                this.dataInscricaoMunicipal.setValue(documento.dataEmissao);
            }
            
        });
        
        this.tipoEstabelecimento.setValue(empresa.fic.tipoUnidade);
        this.cnpjFilial.setValue(empresa.fic.cnpjFilial);
        this.objetoSocial.setValue(empresa.fic.objetoSocial);

 
        if (empresa.criticas.length) {
            if (this.protocolo.criticas.filter( item => item.campo == "inscricaoMunicipal").length) {
                this.inscricaoMunicipal.enable();
                this.dataInscricaoMunicipal.enable();
    
                this.emitirErroInscricaoMunicipal = true;
                this.mensagemErroInscricaoMunicipal = "";

                if (!empresa.redeSim.empresasMesmoCnpj.length) {
                    this.inscricaoMunicipal.disable();
                    this.dataInscricaoMunicipal.disable();
                    this.inscricaoMunicipal.clearValidators();
                    this.dataInscricaoMunicipal.clearValidators();
                    this.mensagemErroInscricaoMunicipal = "Não foram encontrado empresas com esse CNPJ e será gerado uma inscrição municipal, caso já possua uma inscrição favor entrar em contato com a prefeitura.";
                }
                else {
                    this.inscricaoMunicipal.enable();
                    this.dataInscricaoMunicipal.enable();
                    this.inscricaoMunicipal.setValidators(Validators.required);
                    this.dataInscricaoMunicipal.setValidators(Validators.required);
                    this.mensagemErroInscricaoMunicipal = "Foram encontrado empresas com esse CNPJ, caso já possua uma inscrição favor informar nos campos abaixo ou selecionar 'Desejo solicitar uma nova inscrição municipal.', para uma nova inscrição.";
                    this.permitirNovaInscricaoMunicipal = true;
                }
            }
            
            this.mensagemCritica += Protocolo.exibirCriticasFormulario(this.formulario, this.protocolo.criticas, "identificacao");
            if (this.mensagemCritica != "") {
                this.emitirCritica = true;
                if (this.inscricaoEstadual.enabled || this.dataInscricaoEstadual.enabled) {
                    this.inscricaoEstadualIsento.enable();
                    this.inscricaoEstadual.enable();
                    this.dataInscricaoEstadual.enable();
                }
            }
            
        }

        
    }

    preencherCamposAutonomo(dados: Login){
        
        this.cpfAutonomo.setValue(formatToCPFOrCNPJ(dados.login.toString()));
        this.nomeAutonomo.setValue(dados.nome);
        this.nascimentoAutonomo.setValue(dados.nascimento);
        dados.documentos.forEach(documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Rg) {
                    this.rgAutonomo.setValue(documento.numero)
                    this.dataEmissao.setValue(documento.dataEmissao)
                    this.uf.setValue(documento.estadoEmissor)
                    this.emissor.setValue(documento.orgaoEmissor)
            }
        })
        dados.telefones.forEach(telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                this.telefoneFixo.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                this.telefoneComercial.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                this.telefoneCelular.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
        })
        
        var listaDeEmails = "";
        dados.emails.forEach( email => {
            listaDeEmails += email + ";"
        });

        this.email.setValue(listaDeEmails);
        
        var listaDeSites = "";
        dados.sites.forEach( site => {
            listaDeSites += site + ";"
        });

        this.site.setValue(listaDeSites);
        this.sexo.setValue(dados.sexo)
    } 

    preencher() {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
            this.protocolo.fic.nomeEmpresa = this.nomeEmpresarial.value;
            this.protocolo.fic.nomeFantasia = this.nomeFantasia.value;
            this.protocolo.registro.tipo = this.documento.value;
            this.protocolo.registro.porte = this.porte.value;
            this.protocolo.registro.capitalSocial = parseFloat(this.capitalSocial.value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")).toFixed(2);
            this.protocolo.registro.ultimaAlteracao = this.mask.transform(this.dataUltimaAlteracao.value, "00/00/0000");
            this.protocolo.registro.numero = this.nire.value;
            this.protocolo.registro.data = this.mask.transform(this.dataNire.value, "00/00/0000");
    
            let documentoCnpj = new Documento();
            documentoCnpj.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj;
            documentoCnpj.nome = "CNPJ";
            documentoCnpj.numero = this.cnpj.value.replace(/\D/g,"");
            documentoCnpj.dataEmissao = this.mask.transform(this.dataCnpj.value, "00/00/0000");
    
            let documentoEstadual = new Documento();
            documentoEstadual.tipo = Constantes.TIPO_DE_DOCUMENTO.Estadual;
            documentoEstadual.nome = "Inscrição Estadual";
            documentoEstadual.numero = this.inscricaoEstadual.value;
            documentoEstadual.dataEmissao = this.mask.transform(this.dataInscricaoEstadual.value, "00/00/0000");
    
            let documentoMunicipal = new Documento();
            documentoMunicipal.tipo = Constantes.TIPO_DE_DOCUMENTO.Municipal;
            documentoMunicipal.nome = "Inscrição Municipal";
            documentoMunicipal.numero = this.inscricaoMunicipal.value.toString().replace(/\D/g,"");
            documentoMunicipal.dataEmissao = this.mask.transform(this.dataInscricaoMunicipal.value, "00/00/0000");
    
            this.protocolo.registro.documentos = [documentoCnpj, documentoEstadual, documentoMunicipal];
            
            this.protocolo.fic.tipoUnidade = this.tipoEstabelecimento.value;
            this.protocolo.fic.cnpjFilial = this.cnpjFilial.value;
            this.protocolo.fic.objetoSocial = this.objetoSocial.value;
        }
        else {
            
            // Dados de Autônomo
            let documentoAutonomoCpf = new Documento();
            documentoAutonomoCpf.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documentoAutonomoCpf.nome = "CPF";
            documentoAutonomoCpf.numero = this.cpfAutonomo.value;
    
            let documentoAutonomoRg = new Documento();
            documentoAutonomoRg.tipo = Constantes.TIPO_DE_DOCUMENTO.Rg;
            documentoAutonomoRg.nome = "RG";
            documentoAutonomoRg.numero = this.rgAutonomo.value;
            documentoAutonomoRg.dataEmissao = this.mask.transform(this.dataEmissao.value, "00/00/0000");
            documentoAutonomoRg.estadoEmissor = this.uf.value;
            documentoAutonomoRg.orgaoEmissor = this.emissor.value;
    
            let documentoClasse = new Documento();
            documentoClasse.tipo = Constantes.TIPO_DE_DOCUMENTO.OrgaoDeClasse;
            documentoClasse.nome = "Órgão de Classe";
            documentoClasse.estadoEmissor = this.ufRegistro.value == null ? "" : this.ufRegistro.value;
            documentoClasse.numero = this.numeroRegistro.value == null ? "" : this.numeroRegistro.value;
            documentoClasse.dataEmissao = this.dataRegistro.value == null ? "" : this.mask.transform(this.dataRegistro.value, "00/00/0000");
    
            this.orgaoDeClasse.forEach(classe => {
                if(classe.codigo == this.classe.value){
                    documentoClasse.orgaoDeClasse = classe;
                }
            }) 
            
            let documentoMunicipalAutonomo = new Documento();
            documentoMunicipalAutonomo.tipo = Constantes.TIPO_DE_DOCUMENTO.Municipal;
            documentoMunicipalAutonomo.nome = "Inscrição Municipal";
            documentoMunicipalAutonomo.numero = this.inscricaoMunicipal.value.toString().replace(/\D/g,"");
            documentoMunicipalAutonomo.dataEmissao = this.mask.transform(this.dataInscricaoMunicipal.value, "00/00/0000");
            
            
            this.protocolo.registro.documentos = [documentoAutonomoCpf,documentoAutonomoRg,documentoClasse,documentoMunicipalAutonomo];
    
            this.protocolo.registro.nome = this.nomeAutonomo.value;
            this.protocolo.registro.nascimento = this.mask.transform(this.nascimentoAutonomo.value, "00/00/0000");
            this.listaEscolaridade.forEach(escolaridade => {
                if(escolaridade.codigo == this.escolaridade.value){
                    this.protocolo.registro.escolaridade = escolaridade;
                }
            }) 
            
            this.protocolo.registro.sexo = this.sexo.value;
        }
            
        let telefones = new Array<Telefone>();
            
        let telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Residencial;
        telefone.ddd = this.telefoneFixo.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneFixo.value.replace(/\D/g,"").slice(-8);
        telefones.push(telefone);

        telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Comercial;
        telefone.ddd = this.telefoneFixo.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneComercial.value.replace(/\D/g,"").slice(-8);
        telefones.push(telefone);

        telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Celular;
        telefone.ddd = this.telefoneFixo.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneCelular.value.replace(/\D/g,"").slice(-9);
        telefones.push(telefone);

        this.protocolo.fic.telefones = telefones;

        this.protocolo.fic.emails = [];
        this.email.value.split(";").forEach( email => {
            if (email != "") {
                this.protocolo.fic.emails.push(email);
            }
        });

        this.protocolo.fic.sites = [];
        this.site.value.split(";").forEach( site => {
            if (site != "") {
                this.protocolo.fic.sites.push(site);
            }
        });
    }

    preencherInscricaoMunicipal() {
        this.protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                this.inscricaoMunicipal.setValue(documento.numero);
                this.dataInscricaoMunicipal.setValue(documento.dataEmissao);
            }
        });
    }

    get nomeEmpresarial() {
        return this.formulario.get("nomeEmpresarial");
    }  
    set nomeEmpresarial(nomeEmpresarial) {
        this.formulario.get("nomeEmpresarial").setValue(nomeEmpresarial);
    }

    get nomeFantasia() {
        return this.formulario.get("nomeFantasia");
    }  
    set nomeFantasia(nomeFantasia) {
        this.formulario.get("nomeFantasia").setValue(nomeFantasia);
    }

    get email() {
        return this.formulario.get("email");
    }  
    set email(email) {
        this.formulario.get("email").setValue(email);
    }

    get site() {
        return this.formulario.get("site");
    }  
    set site(site) {
        this.formulario.get("site").setValue(site);
    }

    get telefoneFixo() {
        return this.formulario.get("telefoneFixo");
    }  
    set telefoneFixo(telefoneFixo) {
        this.formulario.get("telefoneFixo").setValue(telefoneFixo);
    }

    get telefoneComercial() {
        return this.formulario.get("telefoneComercial");
    }  
    set telefoneComercial(telefoneComercial) {
        this.formulario.get("telefoneComercial").setValue(telefoneComercial);
    }

    get telefoneCelular() {
        return this.formulario.get("telefoneCelular");
    }  
    set telefoneCelular(telefoneCelular) {
        this.formulario.get("telefoneCelular").setValue(telefoneCelular);
    }

    get capitalSocial() {
        return this.formulario.get("capitalSocial");
    }  
    set capitalSocial(capitalSocial) {
        this.formulario.get("capitalSocial").setValue(capitalSocial);
    }

    get documento() {
        return this.formulario.get("documento");
    }  
    set documento(documento) {
        this.formulario.get("documento").setValue(documento);
    }

    get nire() {
        return this.formulario.get("nire");
    }  
    set nire(nire) {
        this.formulario.get("nire").setValue(nire);
    }

    get dataNire() {
        return this.formulario.get("dataNire");
    }  
    set dataNire(dataNire) {
        this.formulario.get("dataNire").setValue(dataNire);
    }

    get dataUltimaAlteracao() {
        return this.formulario.get("dataUltimaAlteracao");
    }  
    set dataUltimaAlteracao(dataUltimaAlteracao) {
        this.formulario.get("dataUltimaAlteracao").setValue(dataUltimaAlteracao);
    }

    get porte() {
        return this.formulario.get("porte");
    }  
    set porte(porte) {
        this.formulario.get("porte").setValue(porte);
    }

    get cnpj() {
        return this.formulario.get("cnpj");
    }
    set cnpj(cnpj) {
        this.formulario.get("cnpj").setValue(cnpj);
    }

    get dataCnpj() {
        return this.formulario.get("dataCnpj");
    }
    set dataCnpj(dataCnpj) {
        this.formulario.get("dataCnpj").setValue(dataCnpj);
    }

    get inscricaoEstadualIsento() {
        return this.formulario.get("inscricaoEstadualIsento");
    }
    set inscricaoEstadualIsento(inscricaoEstadualIsento) {
        this.formulario.get("inscricaoEstadualIsento").setValue(inscricaoEstadualIsento);
    }

    get inscricaoEstadual() {
        return this.formulario.get("inscricaoEstadual");
    }
    set inscricaoEstadual(inscricaoEstadual) {
        this.formulario.get("inscricaoEstadual").setValue(inscricaoEstadual);
    }

    get dataInscricaoEstadual() {
        return this.formulario.get("dataInscricaoEstadual");
    }
    set dataInscricaoEstadual(dataInscricaoEstadual) {
        this.formulario.get("dataInscricaoEstadual").setValue(dataInscricaoEstadual);
    }

    get inscricaoMunicipal() {
        return this.formulario.get("inscricaoMunicipal");
    }
    set inscricaoMunicipal(inscricaoMunicipal) {
        this.formulario.get("inscricaoMunicipal").setValue(inscricaoMunicipal);
    }

    get dataInscricaoMunicipal() {
        return this.formulario.get("dataInscricaoMunicipal");
    }
    set dataInscricaoMunicipal(dataInscricaoMunicipal) {
        this.formulario.get("dataInscricaoMunicipal").setValue(dataInscricaoMunicipal);
    }

    get tipoEstabelecimento() {
        return this.formulario.get("tipoEstabelecimento");
    }
    set tipoEstabelecimento(tipoEstabelecimento) {
        this.formulario.get("tipoEstabelecimento").setValue(tipoEstabelecimento);
    }

    get cnpjFilial() {
        return this.formulario.get("cnpjFilial");
    }
    set cnpjFilial(cnpjFilial) {
        this.formulario.get("cnpjFilial").setValue(cnpjFilial);
    }

    get objetoSocial() {
        return this.formulario.get("objetoSocial");
    }
    set objetoSocial(objetoSocial) {
        this.formulario.get("objetoSocial").setValue(objetoSocial);
    }


    // Dados de Autônomo
    

    get cpfAutonomo() {
        return this.formulario.get("cpfAutonomo");
    }  
    set cpfAutonomo(cpfAutonomo) {
        this.formulario.get("cpfAutonomo").setValue(cpfAutonomo);
    }

    get nomeAutonomo() {
        return this.formulario.get("nomeAutonomo");
    }  
    set nomeAutonomo(nomeAutonomo) {
        this.formulario.get("nomeAutonomo").setValue(nomeAutonomo);
    }

    get nascimentoAutonomo() {
        return this.formulario.get("nascimentoAutonomo");
    }  
    set nascimentoAutonomo(nascimentoAutonomo) {
        this.formulario.get("nascimentoAutonomo").setValue(nascimentoAutonomo);
    }

    get rgAutonomo() {
        return this.formulario.get("rgAutonomo");
    }  
    set rgAutonomo(rgAutonomo) {
        this.formulario.get("rgAutonomo").setValue(rgAutonomo);
    }

    get dataEmissao() {
        return this.formulario.get("dataEmissao");
    }  
    set dataEmissao(dataEmissao) {
        this.formulario.get("dataEmissao").setValue(dataEmissao);
    }

    get uf() {
        return this.formulario.get("uf");
    }  
    set uf(uf) {
        this.formulario.get("uf").setValue(uf);
    }

    get emissor() {
        return this.formulario.get("emissor");
    }  
    set emissor(emissor) {
        this.formulario.get("emissor").setValue(emissor);
    }

    get sexo() {
        return this.formulario.get("sexo");
    }  
    set sexo(sexo) {
        this.formulario.get("sexo").setValue(sexo);
    }

    get escolaridade() {
        return this.formulario.get("escolaridade");
    }  
    set escolaridade(escolaridade) {
        this.formulario.get("escolaridade").setValue(escolaridade);
    }

    get classe() {
        return this.formulario.get("classe");
    }  
    set classe(classe) {
        this.formulario.get("classe").setValue(classe);
    }

    get ufRegistro() {
        return this.formulario.get("ufRegistro");
    }  
    set ufRegistro(ufRegistro) {
        this.formulario.get("ufRegistro").setValue(ufRegistro);
    }

    get numeroRegistro() {
        return this.formulario.get("numeroRegistro");
    }  
    set numeroRegistro(numeroRegistro) {
        this.formulario.get("numeroRegistro").setValue(numeroRegistro);
    }

    get dataRegistro() {
        return this.formulario.get("dataRegistro");
    }  
    set dataRegistro(dataRegistro) {
        this.formulario.get("dataRegistro").setValue(dataRegistro);
    }

    
}