import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Pendencia } from "src/app/model/pendencia";
import { MatCheckbox } from "@angular/material/checkbox";
import Swal from "sweetalert2";
import { PendenciaFluxo } from "src/app/model/pendenciaFluxo";
import { Orgao } from "src/app/model/orgao";
import { filter, take } from 'rxjs/operators';
import { Constantes } from '../../../../model/constantes';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Fase } from '../../../../model/fase';

@Component({
  selector: 'app-atendimento-pendenciar',
  templateUrl: './atendimento-pendenciar.component.html',
  styleUrls: ['./atendimento-pendenciar.component.scss']
})
export class AtendimentoPendenciarComponent implements OnInit {
  
  @Output() selecao = new EventEmitter();
  tipoPendencia = Constantes.TIPO_DE_PENDENCIA;

  formulario: FormGroup;

  public config: PerfectScrollbarConfigInterface = {};
  public pendencias: Array<Pendencia>;

  @Input() dados: Array<any>;
  
  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog) {
    this.pendencias = new Array<Pendencia>();

    this.formulario = formBuilder.group({});
  }

  ngOnInit(): void {
    var indexCritica = 0;
    var solicitacao = [];

    this.dados[0].forEach((pendencia: Pendencia, index) => {
      this.formulario.addControl(`pendencia_${ pendencia.codigo }_${ pendencia.fase }`, new FormControl(""));

      if (pendencia.fluxos[0].orgaoOrigem.codigo != "") {
        this.pendencias.push(pendencia);
      }
      if (pendencia.descricaoTipo == "Crítica De Dados Cadastrais") {
        if ( (this.dados[2].filter( item => item.justificativa != "")).length ) {
          indexCritica = index;
          solicitacao = [{
            orgao: {codigo: pendencia.fluxos[0].orgaoDestino.codigo , nome: pendencia.fluxos[0].orgaoDestino.nome },
            justificativa: "Sr. Contribuinte, algumas informações necessitam de ajustes nos campos destacados."
          }];
        }
      }
    });

    if (solicitacao.length) {
      this.adicionarPendencia(solicitacao, indexCritica);
    }
  }

  exigeJustificativa(checkbox: MatCheckbox, index, tipo){
    console.log(this.dados[1]);
    console.log(this.dados[0][0].fase);
    if (checkbox.checked) {
      if(this.dados[1] == 200){
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '80%',
          height: '95%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Pareceres",
              componente: "atendimento-pareceres",
              dados: [
                "fase",
                localStorage.getItem("pId"), 
                this.dados[1], 
                this.dados[0][0].fase,
                this.dados[3],
                "P",
                tipo == "ALTERAR" ? this.pendencias.filter(pendencia => pendencia.codigo == this.dados[0][index].codigo)[0].fluxos[0].observacao : ""
              ]
          }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result.parecerFinal != null) {
            let solicitacao = new Array<any>();
            let orgaos = new Array<string>();
            
            Object.keys($("input[id=orgaoDestino]:checked")).forEach(index => {
              if ($("input[id=orgaoDestino]:checked")[index].nodeName == "INPUT") {
                orgaos.push( (<HTMLInputElement>$("input[id=orgaoDestino]:checked")[index]).value);
              }
            });
  
            orgaos.forEach(codigo => {
              solicitacao.push({
                orgao: {codigo: codigo, nome: $("input[name=orgaoDestino_" + codigo + "]").attr("nomeOrgao") },
                justificativa: result.parecerFinal
              });
            });
  
            if (this.dados[0][index].fase != "P") {
              solicitacao.push({
                orgao: {codigo: this.dados[0][index].fluxos[0].orgaoDestino.codigo , nome: this.dados[0][index].fluxos[0].orgaoDestino.nome },
                justificativa: result.parecerFinal
              });
            };
  
            this.adicionarPendencia(solicitacao, index);
          }
          else {
            if (tipo != "ALTERAR") {
              checkbox.checked = false;
            }
          }
        });
      }
      else{
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '80%',
          height: '95%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Pareceres",
              componente: "atendimento-pareceres",
              dados: [
                "requisito",
                localStorage.getItem("pId"), 
                this.dados[1], 
                this.dados[3],
                "P",
                tipo == "ALTERAR" ? this.pendencias.filter(pendencia => pendencia.codigo == this.dados[0][index].codigo)[0].fluxos[0].observacao : ""
              ]
          }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result.parecerFinal != null) {
            let solicitacao = new Array<any>();
            let orgaos = new Array<string>();
            
            Object.keys($("input[id=orgaoDestino]:checked")).forEach(index => {
              if ($("input[id=orgaoDestino]:checked")[index].nodeName == "INPUT") {
                orgaos.push( (<HTMLInputElement>$("input[id=orgaoDestino]:checked")[index]).value);
              }
            });
  
            orgaos.forEach(codigo => {
              solicitacao.push({
                orgao: {codigo: codigo, nome: $("input[name=orgaoDestino_" + codigo + "]").attr("nomeOrgao") },
                justificativa: result.parecerFinal
              });
            });
  
            if (this.dados[0][index].fase != "P") {
              solicitacao.push({
                orgao: {codigo: this.dados[0][index].fluxos[0].orgaoDestino.codigo , nome: this.dados[0][index].fluxos[0].orgaoDestino.nome },
                justificativa: result.parecerFinal
              });
            };
  
            this.adicionarPendencia(solicitacao, index);
          }
          else {
            if (tipo != "ALTERAR") {
              checkbox.checked = false;
            }
          }
        });
      }
      
    }
    else {
      let indexPendencia = this.pendencias.findIndex(item => item.codigo == this.dados[0][index].codigo && item.fase == this.dados[0][index].fase);
      this.pendencias.splice(indexPendencia, 1);
      
      Object.keys(this.formulario.controls).forEach(campo => {
        this.formulario.get(campo).enable();
      });

      this.formulario.get(`pendencia_${ this.dados[0][index].codigo }_${ this.dados[0][index].fase }`).setValue("");
    }
  }
  
  pendenciar() {
    if (!this.pendencias.length) {
      Swal.fire("", "Favor informar a(s) pendência(s) que deseja.", "error");
      return false;
    }

    this.selecao.emit({ pendencias: this.pendencias });
  }

  adicionarPendencia(solicitacoes, index) {

    this.controleDeCritica(index);
    
    let pendenciasFluxos = new Array<PendenciaFluxo>();
            
    solicitacoes.forEach(solicitacao => {
      let orgaoOrigem = new Orgao();
      orgaoOrigem.codigo = this.dados[1];

      let orgaoDestino = new Orgao();
      orgaoDestino.codigo = solicitacao.orgao.codigo;
      orgaoDestino.nome = solicitacao.orgao.nome

      let pendenciaFluxo = new PendenciaFluxo();
      pendenciaFluxo.login.login = parseInt(localStorage.getItem("userId"));
      pendenciaFluxo.data = new Date().toLocaleDateString("pt-br");
      pendenciaFluxo.orgaoOrigem = orgaoOrigem;
      pendenciaFluxo.orgaoDestino = orgaoDestino;
      pendenciaFluxo.observacao = solicitacao.justificativa;

      pendenciasFluxos.push(pendenciaFluxo);

    });

    let pendencia = new Pendencia();
    Object.assign(pendencia, this.dados[0][index]);

    pendencia.fluxos = pendenciasFluxos;

    let indexPendencia = this.pendencias.findIndex(item => item.codigo == pendencia.codigo && item.fase == pendencia.fase);
    if (indexPendencia == -1) {
      this.pendencias.push(pendencia);
    }
    else {
      this.pendencias[indexPendencia] = pendencia;
    }

    this.formulario.get(`pendencia_${ this.dados[0][index].codigo }_${ this.dados[0][index].fase }`).setValue("1");
  }

  controleDeCritica(index) {
    if (this.dados[0][index].codigoTipo == Constantes.TIPO_DE_PENDENCIA.Critica_De_Dados_Cadastrais) {
      this.pendencias = new Array<Pendencia>();

      Object.keys(this.formulario.controls).forEach(campo => {
        this.formulario.get(campo).setValue("");
        this.formulario.get(campo).disable();
        if( campo == `pendencia_${ this.dados[0][index].codigo }_${ this.dados[0][index].fase }`) {
          this.formulario.get(campo).enable();
          this.formulario.get(campo).setValue("1");
        }
      });
    }

  }

  acaoDoFiltro(valor) {
    var rex = new RegExp(valor, 'i');
    $('.todo-box .todo-item').hide();
    $('.todo-box .todo-item').filter(function() {
        return rex.test($(this).text());
    }).show();
  }

  acaoOrgaoDestino() {
    $("input[id=orgaoDestino]").on("click", function(){
      $("div[name=" + $(this).attr("name").replace("orgaoDestino", "divJustificativa") + "]").toggle();
    });
  }

}
