import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnexarComponent } from './anexar.component';
import { PipeModule } from '../../../pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    AnexarComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    MatTooltipModule,
  ],
  exports: [
    AnexarComponent
  ],
})
export class AnexarModule {}
