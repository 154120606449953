<div class="justify-content-md-end">
    <form name="formulario" 
        [formGroup]="formulario" 
        class="justify-content-center align-items-center"
        >
        <app-alert type="danger"
            *ngIf="emitirErro" >
            <div [innerHtml]="mensagemErro"></div>
        </app-alert>
        <div class="card mb-0">
        <div class="card-header">
            <div class="col-12">
                <strong>Revisão</strong>
            </div>
        </div>
        </div>
        <div class="row col-lg-12 col-12 pl-0 pr-0">
        <div class="col-12 pl-0 pr-0">
            <div class="card">
                <div class="card-body p-0 mb-2">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 card-header-linha">
                            <p class="card-text pt-2 pb-2">
                                <b>AUDITOR, UTILIZE UMA DAS OPÇÕES PARA LOCALIZAR OS PROCESSAMENTOS REALIZADOS PELO CENTRO DE CUSTO SELECIONADO.</b>
                            </p>                    
                        </div>
                    </div>
                </div>
                <div class="card-body card-body-localizacao">
                <form
                    name="formulario"
                    [formGroup]="formulario"
                >
                    <div class="row justify-content-center align-items-center"> 
                        <div class="col-10">
                            <select 
                                #codigoCentroDeCustoSelect
                                class="form-control form-control-sm"
                                (change)="acaoSelectTipoBusca()"
                                formControlName="codigoCentroDeCusto"
                                name="codigoCentroDeCusto" 
                                id="codigoCentroDeCusto"                
                            >
                                <option disabled value="">--- Selecione ---</option>
                                <option *ngFor="let centroDeCusto of centrosDeCusto; let i = index"
                                    [value]="centroDeCusto.codigoCentroDeCusto" 
                                    >{{ centroDeCusto.identificacao }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-right">
                            <button 
                                type="button" 
                                class="btn btn-warning"
                                title="Buscar"
                                (click)="gerarPreviaRevisao()"
                            >
                            Gerar Prévia
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
        </div>
    </form>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo estão as informações da revisão solicitada, será exibido os cancelamentos e revisões que ocorrerão caso a conclusão seja realizada.<br>
        </p>
    </div> 

    <div class="card" *ngFor="let taxaLancamentoAtual of taxaLancamentosAtual">
        <div class="card-body p-0 mb-2">
            <div class="row justify-content-start align-items-center">
                <div class="col-10 texto-danger-invertido">
                    <p class="card-text pt-2 pb-2 texto-danger-invertido">
                        <b>LANÇAMENTO ATUAL: {{ taxaLancamentoAtual.codigo }} - {{ taxaLancamentoAtual.identificacao }}</b>
                    </p>                    
                </div>
                <div class="col-2 texto-danger-invertido">
                    <p class="card-text pt-2 pb-2 texto-danger-invertido text-right">
                        <b>CANCELADO</b>
                    </p>                    
                </div>
            </div>
        </div>
        <div class="card-body p-0 mb-2">
            <div class="row">
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Avaliação</strong>
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text" class="{{ acaoAtendimentoClass[taxaLancamentoAtual.avaliacao] }}">
                        {{ acaoAtendimentoDescricao[taxaLancamentoAtual.avaliacao] }}
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Quantidade de Parcelas</strong>
                    </p>
                </div>
                <div class="row col-1">
                    <p class="card-text">
                        {{ taxaLancamentoAtual.qtdParcelas }}
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Valor Total</strong>
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text text-right">
                        R$ {{ formatToBRL(taxaLancamentoAtual.total) }}
                    </p>
                </div>
                <div class="row col-1 justify-content-end align-items-center">
                    <button  
                        type="button" 
                        matTooltip="Memória de Cálculo"
                        matTooltipPosition="above"
                        class="btn btn-sm p-0 mr-2"
                        (click)="buscarMemoriaDeCalculo(taxaLancamentoAtual.codigo)"
                        title="Memória de Cálculo">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'book' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="taxaLancamentosAtual == null || !taxaLancamentosAtual.length">
        <div class="card-body p-0 mb-2">
        <div class="row">
            <div class="row col-12 mt-4">
                <p class="card-text text-center">
                    <strong>Não existe(m) lançamentos para ser(em) cancelado(s)</strong>
                </p>
            </div>
        </div>
        </div>
    </div>
    <div class="card" *ngFor="let taxaLancamentoRevisado of taxaLancamentosRevisado">
        <div class="card-body p-0 mb-2">
            <div class="row justify-content-start align-items-center">
                <div class="col-10 texto-success-invertido">
                    <p class="card-text pt-2 pb-2 texto-success-invertido">
                        <b>LANÇAMENTO REVISADO: {{ taxaLancamentoRevisado.codigo }} - {{ taxaLancamentoRevisado.identificacao }}</b>
                    </p>                    
                </div>
                <div class="col-2 texto-success-invertido">
                    <p class="card-text pt-2 pb-2 texto-success-invertido text-right">
                        <b>REVISADO</b>
                    </p>                    
                </div>
            </div>
        </div>
        <div class="card-body p-0 mb-2">
            <div class="row">
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Avaliação</strong>
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text" class="{{ acaoAtendimentoClass[taxaLancamentoRevisado.avaliacao] }}">
                        {{ acaoAtendimentoDescricao[taxaLancamentoRevisado.avaliacao] }}
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Quantidade de Parcelas</strong>
                    </p>
                </div>
                <div class="row col-1">
                    <p class="card-text">
                        {{ taxaLancamentoRevisado.qtdParcelas }}
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text">
                        <strong>Valor Total</strong>
                    </p>
                </div>
                <div class="row col-2">
                    <p class="card-text text-right">
                        R$ {{ formatToBRL(taxaLancamentoRevisado.total) }}
                    </p>
                </div>
                <div class="row col-1 justify-content-end align-items-center">
                    <button  
                        type="button" 
                        matTooltip="Memória de Cálculo Revisado"
                        matTooltipPosition="above"
                        class="btn btn-sm p-0 mr-2"
                        (click)="buscarMemoriaDeCalculoRevisado(taxaLancamentoRevisado.codigo)"
                        title="Memória de Cálculo">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'book' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="taxaLancamentosRevisado == null || !taxaLancamentosRevisado.length">
        <div class="card-body p-0 mb-2">
        <div class="row">
            <div class="row col-12 mt-4">
                <p class="card-text text-center">
                    <strong>Não existe(m) lançamentos para ser(em) regerado(s)</strong>
                </p>
            </div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="row col-12">
            <div class="row col-6 justify-content-start align-items-center">
                <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
            </div>
            <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
            <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
            </div>
        </div>
    </div>
</div>