export class Uso {
    cliente: string;
    protocolo: string;
    codigo: string;
    codigoZona: string;
    tipo: string;
    nome: string;
    nomeResumido: string;
    aplicavelNaAtividade: string;
    aplicavelNaZona: string;
    status: string;
    justificativa: string;
    existeVinculo: boolean;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.codigo = "";
        this.codigoZona = "";
        this.tipo = "";
        this.nome = "";
        this.nomeResumido = "";
        this.aplicavelNaAtividade = "";
        this.aplicavelNaZona = "";
        this.status = "";
        this.justificativa = "";
        this.existeVinculo = false;
    }
}